import { encodeQueryData } from "../../utils/Common";
import http from './httpService';
const API_BASE_URL = process.env.REACT_APP_API_URL

// filterOrder
const getReports = async (params) => {
    params = encodeQueryData(params);
    const url = `${API_BASE_URL}myreports?${params}`;
    const response = await http.get(url);
    return response;
}

const createReport = async (data) => {
    const url = `${API_BASE_URL}myreports`;
    let response = await http.post(url, data);
    return response;
}

const updateReport = async ({id,...data}) => {
    const url = `${API_BASE_URL}myreports/${id}`;
    let response = await http.put(url, data);
    return response;
}

const getReport = async (id) => {
    const url = `${API_BASE_URL}myreports/${id}`;
    const response = await http.get(url);
    return response;
}

const deleteReport = async (id) => {
    const url = `${API_BASE_URL}myreports/${id}`;
    const response = await http.delete(url);
    return response;
}

const PersonalServices = {
    getReports,
    getReport,
    createReport,
    updateReport,
    deleteReport,
}


export default PersonalServices;
