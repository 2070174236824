import React, { useState } from 'react'
import Delayed from '../../../utils/Delayed'
import { AutoComplete, Button, Card, Checkbox, Col, Flex, Form, Input, Modal, Row, Typography } from 'antd'
import { getMenuPortfolio } from '../../../utils/settingCommon'
import { useNavigate } from 'react-router-dom'
import ApplicationLandscapePng from "../../../assets/reports/ApplicationLandscape.png";
import BusinessCapabilityPng from "../../../assets/reports/BusinessCapability.png";
import BusinessProcessPng from "../../../assets/reports/BusinessProcess.png";
import IntegrationLandscapePng from "../../../assets/reports/IntegrationLandscape.png";
import ObjectiveOKRLandscapePng from "../../../assets/reports/Objective(OKR)Landscape.png";
import TechnologyLandscapePng from "../../../assets/reports/TechnologyLandscape.png";
import { DeleteOutlined, FormOutlined, ShareAltOutlined, UserOutlined } from '@ant-design/icons'
import { RecentlyViewedReport } from './PersonalFn'
import moment from 'moment'

const Reports = ({reports,deleteReport,shareReport,loading,loginUserEmail="",updateReport=()=>{},open:visible,setOpen:setVisible,setOpenShareWith=()=>{},openShareWith=false,users=[],editable=true}) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [editReport,setEditReport] = useState(null);
    const [autoCompleteValue,setAutoCompleteValue] = useState(null);

    const navigateTo = (report) => {
        report.report_url = report?.report_url?.replace(window?.location.origin,"") || "";
        navigate(`${report?.report_url}?id=${report?.id}`);
    }

    const editForm = (report) => {
        form.setFieldsValue(report);
        setEditReport(report);
        setVisible(true);
    }

    const shareForm = (report) => {
        setAutoCompleteValue('');
        form2.setFieldsValue(report);
        setEditReport(report);
        setOpenShareWith(true);
    }

    return (
        <Delayed waitBeforeShow={500}>
            {
                ((()=>{
                    if(reports?.length>0){
                        return <Row gutter={[8, 24]}>
                            {
                                reports.map((report)=>{
                                    let moduleData = getMenuPortfolio({moduleName:report?.payload?.moduleName})
                                    return <Col sm={12} md={8} lg={6} xl={4} className='text-center'>
                                        <Card size="small" style={{background:ApplicationLandscapePng}} className={`text-left text-dark`}>
                                            <div className='pointer w-100 text-left d-flex align-items-start' onClick={()=>{ RecentlyViewedReport({type:'set',report}); navigateTo(report) }} >
                                                <Typography.Text
                                                    ellipsis={{
                                                        tooltip: report?.name || "Report",
                                                    }}
                                                    className='h4 mb-0 text-dark'
                                                >
                                                    {report?.name || "Report"}
                                                    <br/>
                                                    {moduleData?.name && `(${moduleData?.name})`}
                                                </Typography.Text>
                                            </div>
                                            <img 
                                                src={((()=>{
                                                    switch (report?.payload?.moduleName) {
                                                        case "business_application": return ApplicationLandscapePng;
                                                        case "business_capability": return BusinessCapabilityPng;
                                                        case "business_processes": return BusinessProcessPng;
                                                        case "integration": return IntegrationLandscapePng;
                                                        case "objective_okr": return ObjectiveOKRLandscapePng;
                                                        case "technology": return TechnologyLandscapePng;
                                                        default: return BusinessProcessPng;
                                                    }
                                                })())} 
                                                style={{
                                                    width:'100%',
                                                    height: '100px'
                                                }}
                                                alt={`Report ${report?.name}`}
                                                className='pointer'
                                                onClick={()=>{ RecentlyViewedReport({type:'set',report}); navigateTo(report); }}
                                            />
                                            <Flex justify='space-between' align='center' className='mt-1'>
                                                <div className='pointer w-100 text-left' onClick={()=>{ RecentlyViewedReport({type:'set',report}); navigateTo(report) }} >
                                                    <UserOutlined />&nbsp;
                                                    <Typography.Text
                                                        ellipsis={{
                                                            tooltip: report?.created_by || "",
                                                        }}
                                                        className='h4 mb-0 pb-0 text-dark'
                                                    >
                                                        {report?.user?.name}
                                                    </Typography.Text>
                                                    <h5 className='mb-0 text-dark'>({moment(report?.createdAt).format('DD-MM-YY H:mm:ss')})</h5>
                                                </div>
                                                <Flex justify='flex-end'>
                                                    {
                                                        (loginUserEmail===report?.useremail) && editable && 
                                                        <>
                                                            <Button size="small" icon={<FormOutlined style={{color:"#0d6efd"}}/>} onClick={()=>{ editForm(report); }} type="text"></Button>
                                                            <Button size="small" icon={<DeleteOutlined style={{color:"#ff0101"}}/>} onClick={()=>{ deleteReport(report?.id) }} type="text"></Button>
                                                            <Button size="small" icon={<ShareAltOutlined style={{color:"#006ee0"}}/>} onClick={()=>{ shareForm(report) }} type="text"></Button>
                                                        </>
                                                    }
                                                    
                                                </Flex>
                                            </Flex>
                                            
                                        </Card>
                                    </Col>
                                })
                            }
                        </Row>
                    }else{
                        return <div className={`text-base font-bold mt-4 ${loading ? "d-none" : ""}`}>No items found.</div>
                    }
                })()) 
            }
            <Modal open={visible} onOk={form.submit} onCancel={()=>{setVisible(false);form.resetFields();}} afterClose={()=>{ form.resetFields(); }}>
				<div className="text-start h2 mb-2">Update</div>
				<Form 
					form={form} 
					onFinish={(values=>{
                        updateReport({
                            ...editReport,
                        })
                    })} 
					layout="vertical" 
					requiredMark={false}
					initialValues={editReport}
				>
					<Form.Item
						label="Name"
						name="name"
						rules={[
							{ required: true, message: 'Please enter your file name!' },
							{
								validator: (_, value) => {
									if (value && typeof value=== "string" && value?.trim() === '') {
										return Promise.reject(new Error(`Filename cannot be just spaces!`));
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						label="Description"
						name="description"
						rules={[
							{ required: false, message: 'Please enter your description!' },
							{
								validator: (_, value) => {
									if (value && typeof value=== "string" && value?.trim() === '') {
										return Promise.reject(new Error(`Filename cannot be just spaces!`));
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<Input.TextArea rows={2}/>
					</Form.Item>
				</Form>
			</Modal>
            <Modal open={openShareWith} onOk={form2.submit} onCancel={()=>{setOpenShareWith(false);form2.resetFields();}} afterClose={()=>{ form2.resetFields(); }}>
                <div className="text-start h2 mb-2">Share With</div>
                <Form 
                    form={form2} 
                    onFinish={(values=>{
                        shareReport({
                            ...editReport,
                            ...values,
                        })
                    })} 
                    layout="vertical" 
                    requiredMark={false}
                    initialValues={editReport}
                >
                    
                    
                        <AutoComplete
                            className='w-100'
                            options={
                                users?.filter(({email})=>email!==loginUserEmail)?.map(({email,name})=>({
                                    label: `${name}(${email})`,
                                    value: email,
                                }))
                            }
                            value={autoCompleteValue}
                            filterOption={(inputValue, option)=>option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase())}
                            onChange={(value)=> setAutoCompleteValue(value)}
                            onSelect={(value, option)=>{
                                if(editReport.share_with){
                                    setEditReport({...editReport,share_with:[...editReport.share_with,value]})
                                }else{
                                    setEditReport({...editReport,share_with:[value]})
                                }
                                
                                setAutoCompleteValue(null)
                            }}
                        />
                        
                        <Checkbox.Group 
                            className='w-100' 
                            value={editReport?.share_with}
                            onChange={(value)=>{
                                setEditReport({...editReport,share_with:value})
                            }}
                        >
                            <Flex vertical style={{maxHeight:window?.innerHeight/2}} className='overflow-y w-100'>
                                {
                                    ((()=>{
                                        if(editReport?.share_with){
                                            return editReport?.share_with?.filter(email=>email!==loginUserEmail)?.map(email=>{
                                                let user = users.find(f=>f.email===email);
                                                if(user){
                                                    return <Checkbox value={email}>{user?.name}({email})</Checkbox>
                                                }else{
                                                    return <Checkbox value={email}>{email}</Checkbox>
                                                }
                                            })
                                        }
                                    })())
                                }
                            </Flex>
                        </Checkbox.Group>
                </Form>
            </Modal>
        </Delayed>
    )
}

export default Reports;
