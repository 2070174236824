import { Image } from 'antd';
import React from 'react'
import GraphContainer from './GraphContainer';
import moment from 'moment';
import { getMenuPortfolio } from '../../../utils/settingCommon';

const DateFormat = "DD-MM-YYYY";
const TimeFormat = "H:mm:ss";
const DateTimeFormat = "DD-MM-YYYY\n H:mm:ss";

export const ActivityFun = () => {
    return (
        <div>
        
        </div>
    )
}

export const columns = [
    {
        title: 'Portfolio Name',
        dataIndex: 'ModuleName',
        render: (value) => {
            if(getMenuPortfolio({routeModuleName:value})?.name){
                return getMenuPortfolio({routeModuleName:value})?.name;
            }else if(value==="BusinessProcess"){
                return "Business Process";
            }else if (value === 'ApqcsProcess') {
                return "APQC Process";
            }else{
                return value;
            }
        },
    },    
    {
        title: 'Portfolio Object',
        dataIndex: 'DataName',
    },
    {
        title: 'Action Type',
        dataIndex: 'TransactionType',
        render: (value) => value?.toUpperCase() || "",
    },
    {
        title: 'Field Name',
        dataIndex: 'DataField',
    },
    {
        title: 'Old Value',
        dataIndex: 'OldValue',
        render: (text,record,index) => {
            return renderContentData(text,record);
        }
    },
    {
        title: 'New Value',
        dataIndex: 'NewValue',
        render: (text,record,index) => {
            return renderContentData(text,record);
        }
    },
    {
        title: 'User',
        dataIndex: 'UserID',
    },
    {
        title: 'Created Date',
        dataIndex: 'createdAt',
        render:(value)=>getDateTime(value),
    },
    {
        title: 'Updated Date',
        dataIndex: 'updatedAt',
        render:(value)=>getDateTime(value),
    },
]?.map(e=>{
    e.sorter = true;
    e.key = e?.dataIndex;
    e.data = e?.dataIndex;
    e.width = e.title?.length>150?e.title?.length:150;
    e.onHeaderCell= () => ({
        style: { whiteSpace: 'nowrap' },
    });
    return e;
});


export const renderContentData = (string,record) => {
    if(string?.startsWith(`data:image/svg`)){
        return <Image
            style={{maxWidth:100,maxHeight:100}}
            className='img-fluid' 
            src={string} 
            alt={record?.DataField}
        />
    }else if(string?.includes(`mxGraphModel`)){
        return <GraphContainer xmlString={string} title={record?.DataField || ""}/>;
    }else{
        return string;
    }
}

export const getDateTime = (value) =>{
    // Format the date and time in two lines
    return `${moment(value).utc().format(DateFormat)}\n ${moment(value).utc().format(TimeFormat)}`;
}