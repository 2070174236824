import React from "react";
import { Input, Modal, Form, message, Spin } from "antd";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BusinessProcessServices from "../../../../services/services/BusinessProcessServices";
import ApqcProcessServices from "../../../../services/services/ApqcProcessServices"
import { UpdateChangeLog } from "../../../../utils/ChangeLogs";
import { useBusinessProcess } from "../../../../Context/businessProcess";
import { useApplicationlink } from "../../../../Context/linkProvider";
import DynamicForm from "../../../dynamicForm/DynamicForm";
import usePortfolioFetcher from "../../../../hooks/usePortfolioFetcher";
import { usePortfolioSetting } from "../../../../Context/portfolioSettingProvider";
import ExtraFormFields from "../../../portfolio/plant/tabs/ExtraFormFields";
import {
  addIsTextToProperties,
  deepFindByName,
} from "../../../../utils/Common";
import { staticSettingPortfolioList } from "../../../../utils/settingCommon";
import { useMenu } from "../../../../Context/MenuProvider";
import { SaveRecentlyView_Portfolio } from "../../../../utils/ResentViewedPortfolio";
import { useApplicationChangeLogs } from "../../../../Context/changeLogsProvider";
import ChangeLogsServices from "../../../../services/services/ChangeLogsServices";
import { usePortfolioTag } from "../../../../Context/portfolioTagProvider";
import useFetchPortfolioTag from "../../../../hooks/useFetchPortfolioTag";

import {
  transformText,
  SavePortfolioItemsCheckModified,
} from "../../../../utils/Common";

import { useGlobalTag } from "../../../../Context/GlobalTagProvider";
import useFetchGlobalProp from "../../../../hooks/useFetchGlobalProp";
import ScenarionServices from "../../../../services/services/ScenarioServices";
import businessCapabilityServices from "../../../../services/services/BusinessCapabilityService";


const TabBPBasicInformation = ({
  setActiveTabKey,
  toggleTabDisabled,
  id: modelId,
  setBusinessProcessData,
  defaultFormData,
  locationData,
}) => {
  const navigate = useNavigate();
  const { loading, fetchPortfolio } = usePortfolioFetcher();
  const { stateMenu, dispatchMenu } = useMenu();
  const [form] = Form.useForm();
  const location = useLocation();
  const leveldata = location.state;
  const [list, setList] = useState([]);
  const [formLoading, setFormLoading] = useState(true);

  const [formData, setFormData] = useState({});
  const [parentList, setParentlist] = useState({});
  const [field_OldData, setfield_OldData] = useState({
    displayName: "",
    LevelId: "",
    description: "",
  });
  const [level, setLevel] = useState();
  const [parentLevel, setParentLevel] = useState();
  const [levelID, setLevelID] = useState();
  const { state, dispatch } = useBusinessProcess();
  const { state: PortfolioSettingState, dispatch: PortfolioSettingDispatch } =
    usePortfolioSetting();
  const { state: stateChangeLog, dispatch: dispatchChangeLog } =
    useApplicationChangeLogs();

  const [formItems, setFormItems] = useState({});
  //let { moduleName } = useParams();

  const { state: businessLinkState, dispatch: businessLinkDispatch } =
    useApplicationlink();

  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const { loading: tagLoading, error: tagError } = stateTag;
  const { error, fetchTagsData } = useFetchPortfolioTag();
  const [allTagsList, setAllTagsList] = useState([]);

  /////
  ///////////////////////////////////////////////////////////
  const [globalTagsList, setGlobalTagsList] = useState([]);
  const { state: stateGlobalTag, dispatch: dispatchGlobalTag } = useGlobalTag();
  const { loading: GlobalTagLoading, error: GlobaltagError } = stateGlobalTag;
  const { error: errorGTag, fetchGlobalPropsData } = useFetchGlobalProp();
  let { id = null, moduleName, scenarioId } = useParams();

  
  const getProcessList =()=>{
    if (moduleName === "BusinessProcesses"){
      return BusinessProcessServices
    } else if (moduleName === "ApqcsProcesses"){
      return ApqcProcessServices
    }
  }
  useEffect(() => {
    fetchData(moduleName, modelId);
  }, [moduleName, modelId]);

  const fetchData = async (moduleName, modelId ) => {
    
    
    try {
      const response = await ChangeLogsServices.getChangeLogsList({
        moduleName,
        modelId,
      });

      const newData = response.data.data;
      await dispatchChangeLog({ type: "SET_LINK", payload: newData });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateBussinessModuleId = async (id) => {
    await businessLinkDispatch({
      type: "SET_BUSINESSAPPLICATIONID",
      payload: id,
    });
    await businessLinkDispatch({
      type: "SET_BUSINESS_APPLICATION_MODEL",
      payload: moduleName,
    });
    await dispatch({ type: "SET_IS_EDIT", payload: true });
  };

  //Get all settings
  const fetchAllPortfolio = async () => {
    try {
      const response = await fetchPortfolio(PortfolioSettingDispatch, 1, 100);
      if (response) {
        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Portfolio not found.");
      }
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    fetchAllPortfolio();
  }, []);

  useEffect(() => {
    const menuDetail = sessionStorage.getItem("sessionMenuID");
  });

  // useEffect(()=>{
  //   console.log("Change Log.....List", list)
  //   console.log("Change Log..... NewList", newList)
  // },[])

  // useEffect(() => {
  //   if (PortfolioSettingState?.data?.length) {
  //     const portfolioValues = deepFindByName(
  //       PortfolioSettingState?.data,
  //       staticSettingPortfolioList?.businessProcess,
  //       false
  //     );
  //     if (portfolioValues?.moduleName) {
  //       dispatchTag({ type: "EMPTY_TAGS" });
  //       dispatchTag({
  //         type: "SET_MODULE_NAME",
  //         payload: portfolioValues?.moduleName,
  //       });
  //     }

  //     setFormItems(addIsTextToProperties(portfolioValues, "Level"));
  //   }
  // }, [PortfolioSettingState?.data]);

  useEffect(() => {
    if (PortfolioSettingState?.data?.length) {
      // const portfolioValues = deepFindByName(
      //   PortfolioSettingState?.data,
      //   staticSettingPortfolioList?.businessCapability,
      //   false
      // );

      const portfolioValues = PortfolioSettingState?.data.find(
        (item) => transformText(item.moduleName, "pascal") === moduleName
      );

      if (portfolioValues?.moduleName) {
        dispatchTag({ type: "EMPTY_TAGS" });
        dispatchTag({
          type: "SET_MODULE_NAME",
          payload: portfolioValues?.moduleName,
        });
      }

      setFormItems(addIsTextToProperties(portfolioValues, "Level"));
    }
  }, [PortfolioSettingState?.data, moduleName, modelId]);

  //////////////////////////////////////////////////////////////////////////
  //Fetch tags items
  const fetchGlobalProp = async () => {
    try {
      await fetchGlobalPropsData();
    } catch (error) {
      console.error("Error fetching Global Prop:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    fetchGlobalProp();
  }, []);

  useEffect(() => {
    setGlobalTagsList(stateGlobalTag.data);
  }, [stateGlobalTag?.data, moduleName, modelId]);

  //////////////////////////////////////////////////////////////////////////

  //Fetch tags items
  const fetchAllPortfolioTags = async () => {
    try {
      await fetchTagsData();
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  // Tag useEffect start
  useEffect(() => {
    if (stateTag?.moduleName) {
      fetchAllPortfolioTags();
    }
  }, [stateTag?.moduleName, moduleName, modelId]);
  // Tag useEffect end

  useEffect(() => {
    setAllTagsList(stateTag.data);
  }, [stateTag?.data, moduleName, modelId]);

  useEffect(() => {
    setFormLoading(true);
    if (modelId != null) {
      updateBussinessModuleId(modelId);
    } else {
      dispatch({ type: "SET_IS_EDIT", payload: false });

      const portfolioValues = PortfolioSettingState?.data.find(
        (item) => transformText(item.moduleName, "pascal") === moduleName
      );


      //Cheking Level Data to Populate the Parent Detail
      if (leveldata != null) {
        console.log("Level Data", leveldata);
        if (leveldata.Level == "1") {
          setLevel("Level 1");
          setParentLevel("");
          setLevelID("");

          if (portfolioValues?.moduleName) {
            
            getMaxLevelID(portfolioValues?.moduleName).then((result) => {  
              defaultFormData.sourceid = 0;
              defaultFormData.levelid = result?.data?.newLevelID.toString(); 

              setFormData({
                ...formData,
                ...defaultFormData 
              });
            });  
          }

        } else if (leveldata.Level == "2") {
          setLevel("Level 2");
          setParentLevel(leveldata.underLevel);
          setLevelID(leveldata.levelID);
        } else if (leveldata.Level == "3") {
          setLevel("Level 3");
          setParentLevel(leveldata.underLevel);
          setLevelID(leveldata.levelID);
        } else if (leveldata.Level > 3) {
          setLevel("Level " + locationData?.newLevelId?.split(".").length);
          setParentLevel(locationData.underLevel);
          setLevelID(locationData.levelID);
        }
      } else {
        setLevel("Level 1");
        setParentLevel("");
        setLevelID("");
      }
      setFormData({
        ...formData,
        ...defaultFormData 
      });

      //Add Level ID when New Scenario Added 
     
      if (scenarioId && id === "add") {
         
        if (portfolioValues?.moduleName) {
          getMaxLevelID(portfolioValues?.moduleName).then((result) => {
        
            setLevel("Level 1");
            setParentLevel("");
            setLevelID(""); 

            defaultFormData.sourceid = 0;
            defaultFormData.levelid = result?.data?.newLevelID.toString();
            setFormData({
              ...formData,
              ...defaultFormData 
            });
          });  
        }
      } 
     
      //setFormData({ ...formData, ...defaultFormData });

    }
    setFormLoading(false);
  }, [modelId, state.isEdit, moduleName]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    console.log("formData.....", formData);
  }, [formData]);

  useEffect(() => {
    //console.log("isEdit", state.isEdit);

    if (modelId && state.isEdit) {
      getData();
    }
  }, [modelId, state.isEdit, moduleName]);

  // useEffect(() => {
  //     getData();
  // }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getMaxLevelID = async (portfolioModuleName) => {
    //LevelID for Scenario Creation
    const ScenarioItemLevelID = await businessCapabilityServices.getmaxlevelid({
      modulename: portfolioModuleName,
    });
    return ScenarioItemLevelID;
    };

  const create_and_Updatd = () => {
    form
      .validateFields()
      .then(async (values) => {
        let obj = {
          referenceid: 413,

          //   NameInSourceSystem: "",
          //   DisplayName: values?.name,
          attributes: [],
          //parentid: 0,
          sourceid: 1,
          //level: "L1",
          //   Description: values?.description,
          //   LevelID: values?.LevelId,
          ...values,
          tags: values?.tags?.map((item) => item?.id),
        };
  
        const { displayname } = obj;
        if (!displayname) {
          return message.error(`Display Name is Required`);
        }

        const oldValues = updateValuesForChangeLog(
          list,
          formItems?.portfolioSections
        );

        const newValues = updateValuesForChangeLog(
          obj,
          formItems?.portfolioSections
        );

        let id = null;

        if (state.isEdit) {
          let obj = {
            // NameInSourceSystem: "",
            // DisplayName: values?.name,
            // Description: values?.description,
            // LevelID: values?.LevelId,
            ...values,
            scenarioId:scenarioId,
            tags: values?.tags?.map((item) => item?.id),
          };
          obj.id = modelId;
          try {
            let result;
            result = await getProcessList().update(obj);

            if (result?.data?.code == "200") { 

              //form.resetFields();
              message.success(result?.data?.msg);

              //Add Change Log
              await UpdateChangeLog(
                obj.id,
                oldValues,
                newValues,
                "Update",
                moduleName,
                formItems?.portfolioSections
              );

              await fetchData({ moduleName, modelId });

              await getData();

              //Update Data modification for scenario portfolio Item
              if (scenarioId) {
                const list_Edit = await BusinessProcessServices.getListByID( modelId);
                
                if (list_Edit.data) {
                  const portfolioValues = PortfolioSettingState?.data.find(
                    (item) =>
                      transformText(item.moduleName, "pascal") === moduleName
                  );
                  if (portfolioValues?.moduleName) {
                    // await SavePortfolioItemsCheckModified({
                    //   moduleName: portfolioValues?.moduleName,
                    //   sourceItemId: parseInt(obj.id, 10),
                    //   targetItemId: list_Edit?.data?.sourceItemId,
                    // });
                    const objdata = {
                      source_id: parseInt(obj.id, 10), 
                      modulename: portfolioValues?.moduleName,
                      tab_name: "Information",
                    };
                    await ScenarionServices.checkscenariomodifyrecord(objdata);
                    
                  } 
                  // setTimeout(() => {
                  //   navigate(`/scenario-list-v2/${scenarioId}`);
                  // }, 1000);
                }
              } ///
            } else {
              message.error(result?.data?.msg);
            }
          } catch (error) {
            // message.error(error?.message);
            message.error(
              "Failed to submit form ." + error.response.data.error
            );
          }
        } else {
          try {
            let result;

            // if(locationData?.scenarioId){
            //   obj.scenarioId = locationData?.scenarioId;
            // }

            if (scenarioId) {
              obj.scenarioId = scenarioId;
              obj.sourceItemType = "New";
            }

            if (leveldata.Level === 1) {
              obj.parentid = obj.parentid || locationData?.ParentID;
              result = await getProcessList().create(obj);
            } else if (leveldata.Level === 2) {
              obj.level = "L2";
              obj.parentid = leveldata?.ParentID;
              result = await getProcessList().create(obj);
            } else {
              obj.level = "L" + obj?.levelid?.split(".").length;
              obj.parentid = leveldata?.ParentID || locationData?.ParentID;
              result = await getProcessList().create(obj);
            }

            if (result.data?.code == "200") {
              modelId = result.data?.data.id;

              //Add Change Log
              await UpdateChangeLog(
                result.data?.data.id,
                oldValues,
                newValues,
                "Add",
                moduleName,
                formItems?.portfolioSections
              );
              await fetchData({ moduleName, modelId });
              form.resetFields();

              await getData();

              message.success(result?.data?.msg);

              //console.log("ID BC Basic Info ", result?.data);
              const newDataID = result.data?.data.id || null;
              if (scenarioId) {
                setTimeout(() => {
                  navigate(`/scenario-list-v2/${scenarioId}`);
                }, 1000);
              } else {
                updateBussinessModuleId(newDataID);

                setTimeout(() => {
                  navigate(`/portfolio/${moduleName}/${newDataID}`);
                }, 1000);
              }
            } else {
              message.error(result?.data?.msg);
            }
          } catch (error) {
            // console.log("Result.....", error);
            //message.error(error?.message);
            message.error(
              "Failed to submit form ." + error.response.data.error
            );
          }
        }
        setFormLoading(false);
      })
      .catch((info) => {
        setFormLoading(false);
        console.log("Validate Failed:", info);
      });
  };

  const updateValuesForChangeLog = (data, propss) => {
    let oldList = {};
    Object.keys(data).map((dataItem) => {
      propss.map((section, index) => {
        section?.properties?.length > 0 &&
          section?.properties
            ?.sort((a, b) => a.name.localeCompare(b.name))
            ?.map((property, index) => {
              //if (dataItem === checkTextCase(property.name)) {
              if (dataItem === property.name) {
                if (property?.type === "Single select") {
                  property?.propertyOptions.map((propvalue) => {
                    if (data[dataItem] == null) {
                      oldList[dataItem] = "";
                    } else if (propvalue?.id === data[dataItem]) {
                      oldList[dataItem] = propvalue?.name;
                    }
                  });
                } else if (property?.type === "Multi select") {
                  let lst = data[dataItem];
                  if (lst?.length) {
                    let propValue = "";
                    for (let i = 0; i < lst?.length; i++) {
                      property?.propertyOptions.map((propvalue) => {
                        if (propvalue?.id === lst[i]) {
                          // console.log("data  Select Name >>>>", propvalue?.name);
                          if (propValue == "") {
                            propValue = propvalue?.name;
                          } else {
                            propValue = propValue + ", " + propvalue?.name;
                          }
                        }
                      });
                    }
                    oldList[dataItem] = propValue;
                  } else {
                    oldList[dataItem] = "";
                  }
                } else {
                  oldList[dataItem] = data[dataItem];
                }
              }
            });
      });
    });
    return oldList;
  };

  const getData = async () => {
    const list1 = await getProcessList().getListByID(modelId);
    setList(list1?.data);
    if (list1?.data.level === "L1") {
      setLevel("Level 1");
    } else if (list1?.data.level === "L2") {
      setLevel("Level 2");
    } else if (list1?.data.level === "L3") {
      setLevel("Level 3");
    } else if (list1?.data?.levelid?.split(".").length > 3) {
      setLevel("Level " + list1?.data?.levelid?.split(".").length);
    }

    let listParent;
    if (list1?.data.parentid != null && list1?.data.parentid != 0) {
      listParent = await getProcessList().getListByID(
        list1?.data.parentid
      );

      //  console.log("list1?.data.ParentID", list1?.data.ParentID);

      setParentlist(listParent?.data);

      setParentLevel(listParent?.data.displayname);
      setLevelID(listParent?.data.levelid);
    } else {
      setLevel("Level 1");
      setParentLevel("");
    }

    ////////////////////////////////////
    setFormData(list1?.data);
    setBusinessProcessData(list1?.data);
    form.setFieldsValue({
      name: list1?.data?.DisplayName,
      LevelId: list1?.data?.LevelID,
      description: list1?.data?.Description,
      sourceID: list1?.data?.sourceid || "",
    });

    setfield_OldData({
      displayName: list1?.data.DisplayName,
      LevelId: list1?.data.LevelID,
      description: list1?.data.Description,
    });

    if (stateMenu.MenuID !== undefined) {
      // SaveRecentlyView_Portfolio(
      //     stateMenu.MenuID.MenuName,
      //     window.location.href,
      //     list1?.data.displayname,
      //     list1?.data.id,
      //     stateMenu.MenuID.color,
      // );

      const portfolioData = PortfolioSettingState?.data.find(
        (item) => transformText(item.moduleName, "pascal") === moduleName
      );

      SaveRecentlyView_Portfolio(
        //stateMenu.MenuID.moduleName,
        portfolioData.name,
        window.location.href,
        list1?.data.displayname,
        list1?.data.id,
        // stateMenu.MenuID.color
        portfolioData.color
      );
    }
  };

  let itemModulename = moduleName.replace(/([A-Z])/g, function(match) {
    return "_" + match.toLowerCase();
  }).replace(/^_/, "");
  return (
    <>
      <Spin spinning={formLoading}>
        <DynamicForm
          data={formItems?.portfolioSections || []}
          form={form}
          formData={formData}
          labelCol={8}
          wrapperCol={16}
          createAndUpdate={create_and_Updatd}
          level={level}
          levelID={levelID}
          parentLevel={parentLevel}
          tagOptions={allTagsList}
          tagLoading={tagLoading}
          moduleName={formItems?.moduleName || null}
          GlobalTagOptions={globalTagsList}
          ExtraFormFields2 ={<ExtraFormFields level={level} formData={formData} form={form} moduleName={itemModulename|| null}/>}
        />
      </Spin>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          className="mt-5"
        >
          <Form.Item
            label="Additional Information Caption"
            name="AdditionalInfoCaption"
            rules={[
              {
                required: true,
                message: "Please input the additional information caption",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="AdditionalInfoValue"
            label="Additional Information Value"
            rules={[
              {
                required: true,
                message: "Please input the additional information caption",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TabBPBasicInformation;
