import { Form, Input, message, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import PersonalServices from '../../services/services/PersonalServices';
import { RecentlyViewedReport } from './personal/PersonalFn';

const ReportsSaveAs = ({reportData=null,id=null,type,visible,setVisible,moduleName,reportFilter,...props}) => {
	const [loading,setLoading] = useState(false);
  	const [form] = Form.useForm();

	useEffect(()=>{
		if(!visible){
			form.resetFields();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[visible])

	const handleSubmit = async (formData) => {
		
		try {
			setLoading(true);
			formData={
				...formData,
				useremail: localStorage.getItem('email'),
				report_url: window?.location?.pathname,
				payload : reportFilter, 
				type,
			}
			
			if(id){
				if(visible==="update"){
					const {data: response} = await PersonalServices.updateReport({...formData,id});
					if(response?.id){
						message.success("Report Updated Successfully");
						RecentlyViewedReport({type:'set',report:response})
						props?.setReportData(response)
						setVisible(false);
					}else{
						message.error("Failed to update report");
					}
				}else{
					const {data: response} = await PersonalServices.createReport(formData);
					if(response?.id){
						message.success("Report Created Successfully")
						RecentlyViewedReport({type:'set',report:response})
						props?.setReportData(response)
						setVisible(false);
					}else {
						message.error("Failed to create report")
					}
				}
				
			}else{
				const {data:response} = await PersonalServices.createReport(formData);
				if(response?.id){
					message.success("Report Created Successfully")
					RecentlyViewedReport({type:'set',report:response})
					props?.setReportData(response)
					setVisible(false);
				}else {
					message.error("Failed to create report")
				}
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			if(id){
				message.success("Failed to update report")
			}else{
				message.error("Failed to create report")
			}
		} finally{
			setLoading(false);
		}
	}

  	return <React.Fragment>
		<Spin spinning={loading}>
			<Modal open={visible} onOk={form.submit} onCancel={()=>{setVisible(false);form.resetFields();}} afterClose={()=>{ form.resetFields(); }}>
				<div className="text-start h2 mb-2">{visible==="update"?'Update':'Save As'}</div>
				<Form 
					form={form} 
					onFinish={handleSubmit} 
					layout="vertical" 
					requiredMark={false}
					initialValues={reportData}
				>
					<Form.Item
						label="Name"
						name="name"
						rules={[
							{ required: true, message: 'Please enter your file name!' },
							{
								validator: (_, value) => {
									if (value && typeof value=== "string" && value?.trim() === '') {
										return Promise.reject(new Error(`Filename cannot be just spaces!`));
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						label="Description"
						name="description"
						rules={[
							{ required: false, message: 'Please enter your description!' },
							{
								validator: (_, value) => {
									if (value && typeof value=== "string" && value?.trim() === '') {
										return Promise.reject(new Error(`Filename cannot be just spaces!`));
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<Input.TextArea rows={2}/>
					</Form.Item>
				</Form>
			</Modal>
		</Spin>
  	</React.Fragment>
}

export default ReportsSaveAs;