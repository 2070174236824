import { Col, Row, } from "antd";
import React, { useEffect, useState } from "react";
import CompletenessDataTable from "./CompletenessDataTable";
import { getAllMenuPortfolio } from "../../../utils/settingCommon";
import DCBarCharts2 from "./DCBarCharts2";
import { default_pageSizeOptions } from "../../../utils/Common";

const DCViewDetails2 = ({
  portfolioData,
  data,
  dataRelations = [],
  handleGetDetail,
  exportImgLoading= false,
  ...props
}) => {
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [pageSizeOptions, setPageSizeOptions] = useState(default_pageSizeOptions);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    setPagination({
      current: 1,
      pageSize: 10,
      total: Number(portfolioData?.totalRecords) || 0,
    });
    let psoptions = [...default_pageSizeOptions,Number(portfolioData?.totalRecords)];
    psoptions = [...new Set(psoptions)];
    psoptions.sort(function(a, b) { return a - b; });
    setPageSizeOptions(psoptions);
    fetchDetailsData(1, 10);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioData?.name]);

  useEffect(() => {
    if(pagination.current>0){
      fetchDetailsData(pagination.current, pagination.pageSize);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.current, pagination.pageSize]);

  const fetchDetailsData = async (page, pageSize) => {
    setDetailsLoading(true);
    try {
      if(page===0){ return };
      pageSize = pageSize==="All" ? (Number(portfolioData?.totalRecords) || 0) : pageSize;
      let data = await handleGetDetail(portfolioData?.id, page, pageSize);
      const selectedItem = getAllMenuPortfolio()?.find((item)=> item?.id === portfolioData?.id);
      if (selectedItem) {
        const allProperties = selectedItem?.portfolioSections?.flatMap(section => 
          section?.properties ?? []
        ) ?? [];
        const keyName = allProperties?.length && allProperties?.find((property)=>property?.name === 'displayname')?.displayName || "";
        if (data?.length > 0 && keyName) {
           data = data?.map(item => {
            const { displayname, ...rest } = item;
            const newItem = { [`${keyName || selectedItem?.name}s`]: displayname, ...rest }
            return newItem; 
          });
        }
      }
      
      setDetailsData(data);
    } catch (error) {
      console.error("Error fetching details data:", error);
    } finally {
      setDetailsLoading(false);
    }
  };

  const handlePageChange = (newPagination, filters, sorter) => {
    const { current, pageSize } = newPagination;
    if(pageSize !== pagination.pageSize){
      setPagination((prev) => ({
        ...prev,
        current: 1,
        total: Number(portfolioData?.totalRecords) || 0,
        pageSize,
      }));
    }else
    if (current !== pagination.current) {
      setPagination((prev) => ({
        ...prev,
        current: current,
        total: Number(portfolioData?.totalRecords) || 0,
        pageSize,
      }));
    }
  };
  const checkBoolean = (obj) =>
    Object.values(obj).some((value) => (Array.isArray(value) ? true : false));

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          {(data?.length > 0 && (
            <DCBarCharts2 portfolioData={portfolioData} data={data} width={50} title={`Missing Property Summary`}/>
          )) || (
            <div className="no-results-message"><b>Missing Property Summary Not Found!</b></div>
          )}
        </Col>
        <Col span={24}>
          {(dataRelations?.length > 0 && (
            <DCBarCharts2
              portfolioData={portfolioData}
              data={dataRelations || []}
              width={50}
              title={`Missing Relation Summary`}
            />
          )) || <div className="no-results-message"><b>Missing Relation Summary Not Found!</b></div>}
        </Col>
		{(dataRelations?.length > 0 && data?.length > 0 && (
			<Col span={24} className="mb-5">
			  <CompletenessDataTable
				data={detailsData}
				width="100%"
				height={exportImgLoading?detailsData?.length*30:300}
				style={{ margin: "20px 0px" }}
				paginationOptions={{
				  ...pagination,
				  showTotal:(total) => `Total ${total} items`,
				  showSizeChanger: true,
				  pageSizeOptions: pageSizeOptions, // Options for page size
				}}
				columnHeight={60} // Custom column height
				columnWidth={200} // Custom column width
				position="left" // Position for the first column
				rowHeight={20}
				rowKey="id"
				size="small"
				loading={detailsLoading}
				onChange={handlePageChange}
				portfolioData={portfolioData}
			  />
			</Col>
		))}
      </Row>
    </React.Fragment>
  );
};

DCViewDetails2.defaultProps = {};

export default DCViewDetails2;

const randomIntFromInterval = (min, max) => {
  // min and max included
  return parseInt(Math.floor(Math.random() * (max - min + 1) + min));
};
