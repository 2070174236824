import React from 'react'
import { NotificationItemContent } from './NotificationPage';

const NotificationAlerts = ({tabKey="",notificationDataAlert}) => {

     return (
        <React.Fragment>
            <div className='notifications mb-2'>
                <ul>
                    {
                        notificationDataAlert?.length > 0 &&
                        notificationDataAlert?.map(item=>{
                            return <React.Fragment key={Math.random()}>
                                <li>
                                    <NotificationItemContent item={item} tabKey={tabKey}/>
                                </li>
                            </React.Fragment>
                        })|| "No items found"
                    }
                </ul>
            </div>
        </React.Fragment>
    )
}

export default NotificationAlerts
