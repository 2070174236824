/* eslint-disable jsx-a11y/alt-text */
import {
  Button,
  Col,
  Divider,
  Drawer,
  Flex,
  Modal,
  Row,
  Slider,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useState, useMemo } from "react";
import parse from "html-react-parser";
import diagramServices from "../../../../services/services/diagramServices";
import TextWithTooltip from "../../../../utils/TextWithTooltip";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  FullscreenExitOutlined,
  FullscreenOutlined,
  // EyeOutlined,
  DeploymentUnitOutlined,
  PlusOutlined,
  MinusOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import AddDiagram from "./AddDiagram";
import { useLocation, useNavigate } from "react-router";
import { PageTitleHeading, snakeToPascal } from "../../../../utils/Common";
import DiagramProjectSvg from "./../../../../assets/diagram-project-solid.svg";
import { diagramTypes } from "../../../custom/CustomDiagramModal";
import BpmnServices from "../../../../services/services/BpmnServices";
import BpmnMxGraph from "../../../bpmnDiagram/BpmnMxGraph";
import DiagramData from "./DiagramData";
import { checkRole } from "../../../../helper/useUserData";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useParams } from "react-router";
import PortfolioSettingServices from "../../../../services/services/PortfolioSettingsService";
import ScenarionServices from "../../../../services/services/ScenarioServices";
import ExcelJS from 'exceljs';
import ExportSvg from "../../../../../src/assets/Export.svg";
import ImportSvg from "../../../../../src/assets/import.svg";
import ImportApplicationData from "../../../applicationlist/ImportApplicationData";

const colorStyleCard = {
  width: 15,
  height: 15,
  border: "1px solid #ddd",
  borderRadius: 3,
};

const fileName = 'DiagramAsData.xlsx';

const DiagramScreen = ({
  titleName,
  setLoading,
  moduleName,
  modelId,
  moduleData,
  refreshTab,
  setrefreshTab,
  ...props
}) => {
  const location = useLocation();
  let navigate = useNavigate();
  const [portfolioSetting, setPortfolioSetting] = useState([]);
  const [diagramsData, setDiagramsData] = useState([]);
  const [selectdiagramData, setSelectDiagramData] = useState();
  // const [imageScreen, setImageScreen] = useState(false);
  const [editDiagramTitle, setEditDiagramTitle] = useState(null);
  const [getXmlData, setXmlData] = useState();
  const [getPreviewDiagram, setPreviewDiagram] = useState();
  const [fullscreen, setFullscreen] = useState(false);
  const [resize, setResize] = useState(100);
  const handle = useFullScreenHandle();
  let {
    id: modelIDScenario,
    moduleName: moduleNameTemp,
    scenarioId,
  } = useParams();

  useEffect(()=>{
    setResize(100);
  },[titleName,moduleName,modelId,moduleData,fullscreen]);

  useEffect(() => {
    getDiagrams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId, moduleName, getXmlData]);

  const getDiagrams = async () => {
    try {
      setLoading(true);
      const response = await diagramServices.getListByModule({
        moduleName: moduleName,
        moduleID: modelId,
      });
      setDiagramsData(response?.data?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    } finally {
      setEditDiagramTitle(null);
      setSelectDiagramData(null);
    }
  };

  const getImageScreen = () => {
    return (
      <React.Fragment>
        <FullScreen handle={handle} className="p-2">
          <div className="card w-100 border-0">
            <div className="card-header d-flex justify-content-between align-items-center bg-none border-0 border-bottom p-1">
              <PageTitleHeading text={selectdiagramData?.name} size={4} className={`mb-0 pb-0`}/>
              {/* <Tooltip title={fullscreen?'Exit':'Fullscreen'}> */}
              <Flex gap={8} justify="flex-end" align="center">
                {
                  fullscreen && 
                  <React.Fragment>
                    <Tooltip title="Maximize"><Button icon={<ZoomInOutlined />} onClick={()=>{ if(resize>=0){setResize(resize+5) }}}/></Tooltip>
                    <Tooltip title="Minimize"><Button icon={<ZoomOutOutlined />} onClick={()=>{ if(resize>5){setResize(resize-5) }}}/></Tooltip>
                    <Tooltip title="Minimize"><Button onClick={()=>{ if(resize>0){setResize(100) }}}>Reset</Button></Tooltip>
                  </React.Fragment>
                }
                <Button
                  onClick={() => {
                    setFullscreen(!fullscreen);
                  }}
                  icon={
                    fullscreen ? (
                      <FullscreenExitOutlined />
                    ) : (
                      <FullscreenOutlined />
                    )
                  }
                />
              </Flex>
            </div>
            <div className="card-body">
              {
                selectdiagramData?.name && 
                <Flex gap={8} justify="flex-end" align="center" >                  {/* <Slider defaultValue={1} value={resize} min={1} max={1000} onChange={(v)=>{ setResize(v) }} style={{width:100}}/>{resize} */}
                </Flex>
              }
              <div style={{zoom:`${resize}%`}}>
                {(() => {
                  if (selectdiagramData?.diagram_type === "embed_diagram") {
                    return (
                      <div className="embed-responsive embed-responsive-16by9">
                        {selectdiagramData?.embed_diagram &&
                          parse(selectdiagramData?.embed_diagram)}
                      </div>
                    );
                  } else if (
                    selectdiagramData?.diagram_type === "data_as_diagram" ||
                    getPreviewDiagram
                  ) {
                    return (
                      (selectdiagramData?.xml || getPreviewDiagram) && (
                        <DiagramData
                          dataGraphXML={
                            selectdiagramData?.xml || getPreviewDiagram
                          }
                        />
                      )
                    );
                  } else {
                    return (
                      selectdiagramData?.svg && (
                        <object
                          data={selectdiagramData?.svg}
                          alt={selectdiagramData?.name}
                          className="img-fluid"
                          type="image/svg+xml"
                          style={{ maxHeight: window?.innerHeight / 2 }}
                        ></object>
                      )
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </FullScreen>
      </React.Fragment>
    );
  };

  //Start Diagram Data
  const columnNames = useMemo(
    () => ({
      swimline: "",
      stepname: "",
      followingsteps: "",
      datavalue: "",
      events: "",
      steptype: "",
      eventlinks: "",
      eventstype: "",
      datavaluetype: "",
      modulename: "",
      diagramid: "",
      id: "",
    }),
    []
  );

  useEffect(() => {
    getPortfolioData();
  }, []);

  const [rows, setRows] = useState([columnNames]);
  const [newRows, setNewRows] = useState([])
  const [dataGraphStep, setDataGraphStep] = useState([]);
  const [showDataDiagramForm, setShowDataDiagramForm] = useState(false);
  const [showDiagramId, setDiagramId] = useState();
  const [openImportModal, setOpenImportModal] = useState(false);
  const [saveDiagramBpmn, setSaveDiagramBpmn] = useState(false);

  useEffect(() => {
    setNewRows(rows)
  }, [rows])

  const getPortfolioData = async () => {
    try {
      const response = await PortfolioSettingServices.getList({
        page: 1,
        limit: 1000,
      });

      const newData = response.data.data;
      setPortfolioSetting(newData);
    } catch (error) {
      message.error("Something went wrong");
    } finally {
    }
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        ...columnNames,
        modulename: moduleName,
        diagramid: showDiagramId,
      },
    ]);
  };

  const handleRemoveRow = (index, id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      icon: <DeleteOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        if (id && id != "") {
          await BpmnServices.deleteBpmnById(id);
          const fetchResponse = await BpmnServices.getBpmnRecords(
            showDiagramId,
            moduleName
          );
          const xmlData = BpmnMxGraph({ data: fetchResponse.data });
          await BpmnServices.saveXMLData(showDiagramId, xmlData);
          setXmlData(xmlData);
          message.success("Deleted successfully");
        }
        setRows(rows.filter((_, i) => i !== index));
      },
      onCancel() {
        console.log("Deletion canceled");
      },
    });
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = rows.map((row, i) =>
      i === index ? { ...row, [name]: value } : row
    );
    setRows(updatedRows);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await BpmnServices.saveBpmnRecords({ data: rows });

      //if (response.statusText == 'OK') {
      const fetchResponse = await BpmnServices.getBpmnRecords(
        showDiagramId,
        moduleName
      );
      const xmlData = BpmnMxGraph({ data: fetchResponse.data });
      await BpmnServices.saveXMLData(showDiagramId, xmlData);
      setXmlData(xmlData);
      await showDiagramData(showDiagramId);
      message.success("Data saved successfully");
      setLoading(false);
      setrefreshTab(!refreshTab)



      //} else {
      //console.error('Failed to save data');
      // }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePreviewDiagram = async (event) => {
    event.preventDefault();
    try {
      const fetchResponse = await BpmnServices.getPreviewDiagram({
        data: rows,
      });
	  if(fetchResponse.data.length > 0)
      {
		const xmlData = BpmnMxGraph({ data: fetchResponse.data });
		setPreviewDiagram(xmlData);
		getImageScreen();
	  }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (selectdiagramData) {
      setShowDataDiagramForm(false);
    }
  }, [selectdiagramData]);

  const showDiagramData = async (id) => {
    try {
      setLoading(true);
      setDiagramId(id);
      const response = await BpmnServices.getBpmnById(moduleName, id);
      const data = response.data || [];
      setRows(
        data.map((row) => ({
          ...row,
          modulename: moduleName,
          diagramid: id,
        }))
      );
      const stepTypeResponse = await BpmnServices.getBpmnStepType();
      setDataGraphStep(stepTypeResponse.data);
      setShowDataDiagramForm(true);
      setSelectDiagramData(null);
      setPreviewDiagram(null);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const exportDiagram = () => {
    setLoading(true)
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    const aa = dataGraphStep.map((item) => item.steptype)
    const swimData = ["Main", "Intake"];

    if (newRows[0].swimline && typeof (newRows[0].swimline) !== "object") {
      newRows.forEach(obj => {
        // Find the original values
        const originalSwimline = obj.swimline
        const originalSteptype = obj.steptype;
        const originalDatavaluetype = obj.datavaluetype;
        const originalEventstype = obj.eventstype;

        // Create new arrays with the original values at the 0th index
        const reorderedSwimline = [originalSwimline, ...swimData.filter(item => item !== originalSwimline)];
        const reorderedSteptype = [originalSteptype, ...aa.filter(item => item !== originalSteptype)];
        const reorderedDatavaluetype = [originalDatavaluetype, ...aa.filter(item => item !== originalDatavaluetype)];
        const reorderedEventstype = [originalEventstype, ...aa.filter(item => item !== originalEventstype)];

        obj.steptype = reorderedSteptype;
        obj.datavaluetype = reorderedDatavaluetype;
        obj.eventstype = reorderedEventstype;
        obj.swimline = reorderedSwimline;
      });
    }

    const headerRow = [
      "id",
      "swimline",
      "stepname",
      "steptype",
      "followingsteps",
      "datavaluetype",
	    "datavalue",
      "eventstype",
	    "events",
      "eventlinks",
      // "createdAt",
      // "updatedAt",
      // "modulename",
      // "diagramid"
    ];
    worksheet.addRow(headerRow);

    // Add the data rows
    newRows.forEach((row, index) => {
      const rowValues = [
          row.id,
          row.swimline[0],
          row.stepname,
          row.steptype[0],
          row.followingsteps,
          row.datavaluetype[0],
          row.datavalue,
          row.eventstype[0],
          row.events,
          row.eventlinks
      ];
      worksheet.addRow(rowValues);
    });

    worksheet.columns = [
      { header: 'ID', key: 'id', width: 10 },
      { header: 'Swimline', key: 'swimline', width: 15 },
      { header: 'Step Name', key: 'stepname', width: 20 },
      { header: 'Step Type', key: 'steptype', width: 20 },
      { header: 'Following Steps', key: 'followingsteps', width: 20 },
      { header: 'Data Value Type', key: 'datavaluetype', width: 20 },
      { header: 'Data Value', key: 'datavalue', width: 20 },
      { header: 'Event Type', key: 'eventstype', width: 20 },
      { header: 'Events', key: 'events', width: 20 },
      { header: 'Event Link', key: 'eventlinks', width: 20 }
    ];

    worksheet.dataValidations.add('B2:B2000', {
        type: 'list',
        allowBlank: true,
        formulae: [`"${swimData.join(',')}"`],
    });

    worksheet.dataValidations.add('D2:D2000', {
        type: 'list',
        allowBlank: true,
        formulae: [`"${aa.join(',')}"`],
    });

    worksheet.dataValidations.add('F2:F2000', {
        type: 'list',
        allowBlank: true,
        formulae: [`"${aa.join(',')}"`],
    });

    worksheet.dataValidations.add('H2:H2000', {
        type: 'list',
        allowBlank: true,
        formulae: [`"${aa.join(',')}"`],
    });

    // write the workbook to a file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
    });
    setLoading(false);
  }


  const getDataDigramForm = () => {
    return (
      <>
        {showDataDiagramForm && (
          <>
            <form onSubmit={handleSubmit}>
              <br />
              <br />
              <div style={{display:'flex', flexDirection:'row'}}>
              <Button
                type="primary"
                onClick={handlePreviewDiagram}
                style={{ marginBottom: "8px" }}
              >
                Preview Diagram
              </Button>
              {/* <Button
                type="primary"
                style={{ marginLeft: "8px" }}
                disabled={rows && rows.length > 0 ? false : true}
                onClick={(event) => {
                  event.preventDefault()
                  exportDiagram()
                }}
              >
                Export XLS
              </Button> */}
              <Tooltip title={`Import`}>
                <Button
                   style={{ marginLeft: "8px" }}
                  icon={<img src={ImportSvg} alt={`Import`} width={25} />}
                  onClick={() => {
                    setOpenImportModal(!openImportModal);
                  }}
                />
              </Tooltip>
              <Tooltip title={`Export`}>
                <Button
                style={{ marginLeft: "8px" }}
                icon={<img src={ExportSvg} alt={`Export`} width={25} />}
                disabled={rows && rows.length > 0 ? false : true}
                  onClick={(event) => {
                    event.preventDefault()
                    exportDiagram()
                  }}
                />
              </Tooltip>
              </div>
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  style={{ border: "2px solid #dee2e6" }}
                >
                  <thead>
                    <tr>
                      <th style={{ width: 100 }}>Swimline</th>
                      <th>Step Name</th>
                      <th style={{ width: 100 }}>Step Type</th>
                      <th>Following Steps</th>
					  <th style={{ width: 100 }}>Data Type</th>
                      <th>Data</th>
                      <th style={{ width: 100 }}>Event Type</th>
					  <th>Events</th>
                      <th>Events Link</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="hidden"
                            name="id"
                            value={row.id || ""}
                            onChange={(e) => handleChange(index, e)}
                          />
                          <input
                            type="hidden"
                            name="modulename"
                            value={rows[0]?.modulename}
                            onChange={(e) => handleChange(0, e)}
                          />
                          <input
                            type="hidden"
                            name="diagramid"
                            value={rows[0]?.diagramid}
                            onChange={(e) => handleChange(0, e)}
                          />
                          <select
                            class="form-control"
                            name="swimline"
                            value={row.swimline}
                            onChange={(e) => handleChange(index, e)}
                          >
                            <option value="">Select</option>
                            <option value="Main">Main</option>
                            <option value="Intake">Intake</option>
                          </select>
                        </td>
                        <td>
                          <input
                            class="form-control"
                            type="text"
                            name="stepname"
                            value={row.stepname}
                            onChange={(e) => handleChange(index, e)}
                          />
                        </td>
                        <td>
                          <select
                            class="form-control"
                            name="steptype"
                            value={row.steptype}
                            onChange={(e) => handleChange(index, e)}
                          >
                            <option value="">Select</option>
                            {dataGraphStep.map((item) => (
                              <option value={item?.steptype}>
                                {item?.steptype}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            class="form-control"
                            type="text"
                            name="followingsteps"
                            value={row.followingsteps}
                            onChange={(e) => handleChange(index, e)}
                          />
                        </td>
						<td>
                          <select
                            class="form-control"
                            name="datavaluetype"
                            value={row.datavaluetype}
                            onChange={(e) => handleChange(index, e)}
                          >
                            <option value="">Select</option>
                            {dataGraphStep.map((item) => (
                              <option value={item?.steptype}>
                                {item?.steptype}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            class="form-control"
                            type="text"
                            name="datavalue"
                            value={row.datavalue}
                            onChange={(e) => handleChange(index, e)}
                          />
                        </td>
						<td>
                          <select
                            class="form-control"
                            name="eventstype"
                            value={row.eventstype}
                            onChange={(e) => handleChange(index, e)}
                          >
                            <option value="">Select</option>
                            {dataGraphStep.map((item) => (
                              <option value={item?.steptype}>
                                {item?.steptype}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            class="form-control"
                            type="text"
                            name="events"
                            value={row.events}
                            onChange={(e) => handleChange(index, e)}
                          />
                        </td>
                        <td>
                          <input
                            class="form-control"
                            type="text"
                            name="eventlinks"
                            value={row.eventlinks}
                            onChange={(e) => handleChange(index, e)}
                          />
                        </td>
                        <td>
                          <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            size={`large`}
                            onClick={() => handleRemoveRow(index, row.id || "")}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Button type="default" onClick={handleAddRow}>
                Add Row
              </Button>
              <Button
                type="primary"
                onClick={handlePreviewDiagram}
                style={{ marginLeft: "8px" }}
              >
                Preview Diagram
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: "8px" }}
              >
                Save
              </Button>
            </form>
          </>
        )}
      </>
    );
  };
  //End Diagram Data

  const deleteDiagram = async (id) => {
    try {
      setLoading(true);
      Modal.confirm({
        centered: true,
        title: "Are you sure want to delete?",
        icon: <ExclamationCircleFilled />,
        onOk: async () => {
          // console.log('OK');
          const response = await diagramServices.deletediagram(id);

          //Update Modify in portfolio in Case of Scenario
          if (scenarioId) {
			let modifiedModuleName = moduleName;
			if (moduleName === 'BusinessProcess') {
			  modifiedModuleName = 'BusinessProcesses';
			}
			if (moduleName === 'ApqcsProcess') {
			  modifiedModuleName = 'ApqcsProcesses';
			}
			
            const modulenameScenario = portfolioSetting?.find(
              (item) => snakeToPascal(item?.moduleName) === modifiedModuleName
            );

            const objdata = {
              source_id: modelIDScenario,
              modulename: modulenameScenario?.moduleName,
              tab_name: "Diagram",
            };

            const obj = await ScenarionServices.checkscenariomodifyrecord(
              objdata
            );

          }

          //const newData = response?.data || null;
          // Handle success response
          message.success("Deleted Successfully");
          setTimeout(() => {
            setLoading(false);
            getDiagrams();
          }, 1000);
          setShowDataDiagramForm(false);
        },
        onCancel() {
          // console.log('Cancel');
          setLoading(false);
        },
      });
    } catch (error) {
      console.log("catcheEror", error);
      setLoading(false);
    }
  };

  const editDiagram = (dig) => {
    if(dig?.diagram_type==="diagram_editor"){
      navigate(`/diagram-editor/${dig?.id}`, {
        state: {
          previousPath: location?.pathname,
          titleName: titleName,
          moduleName: moduleName,
        },
      });
    }else if(dig?.diagram_type==="bpmn_editor"){
      navigate(`/bpmn-editor/${dig?.id}`, {
        state: {
          previousPath: location?.pathname,
          titleName: titleName,
          moduleName: moduleName,
        },
      });
    }
  };

  return (
    <div>
      <div className="txtdes">
        <p>
          (This screen shows diagrams created for{" "}
          <span className="diagramtxtcolr" style={{ color: "#ffc107" }}>{`"${
            titleName || ""
          }"`}</span>
          . It also shows other diagram which references{" "}
          <span className="diagramtxtcolr" style={{ color: "#ffc107" }}>{`"${
            titleName || ""
          }"`}</span>
          .)
        </p>
      </div>
      <hr className="my-3" />
      <Row gutter={8}>
        <Col span={11}>
          <h3 className="mb-0">
            <span className="diagramtxtcolr" style={{ color: "#ffc107" }}>
              "{titleName || ""}"
            </span>{" "}
            Diagram
          </h3>
          <Flex gap={8} align="center">
            {diagramTypes?.map((item) => {
              return (
                <Flex gap={8} key={Math.random()} align="center">
                  <div style={{ ...colorStyleCard, background: item?.color }} />
                  <div>{item?.label}</div>
                </Flex>
              );
            })}
          </Flex>
          <div
            className={`overflow-y pr-3 ${
              diagramsData?.length > 0 ? `diagramlist` : ``
            }`}
            style={{ maxHeight: 240 }}
          >
            {diagramsData?.map((diagram, index) => {
              return (
                <div className={`card card-body p-0 mb-2`} key={Math.random()}>
                  <Flex justify="space-between" align="center">
                    <div
                      type={`text`}
                      className="w-100 px-2 cursor-pointer my-2"
                      onClick={() => {
                        setSelectDiagramData(diagram);
                      }}
                    >
                      <Flex gap={8} key={Math.random()} align="center">
                        {(() => {
                          let diagramType = diagramTypes.find(
                            (f) => f.value === diagram?.diagram_type
                          );
                          return (
                            <div
                              style={{
                                ...colorStyleCard,
                                background: diagramType?.color,
                              }}
                            />
                          );
                        })()}
                        <TextWithTooltip
                          text={diagram?.name}
                          characterLimit={50}
                        />
                      </Flex>
                    </div>
                    <Flex wrap gap={8}>
                      {(["diagram_editor","bpmn_editor"].includes(diagram?.diagram_type)) && (
                        <Tooltip title="Edit Diagram">
                          <Button
                            type="text"
                            icon={
                              <img
                                src={DiagramProjectSvg}
                                alt={`DiagramProjectSvg`}
                                width={"25px"}
                              />
                            }
                            size={`large`}
                            onClick={() => {
                              editDiagram(diagram);
                            }}
                          />
                        </Tooltip>
                      )}
                      {diagram?.diagram_type === "data_as_diagram" &&
                        checkRole("update") && (
                          <Tooltip title="Edit Diagram Data">
                            <Button
                              type="text"
                              icon={<DeploymentUnitOutlined />}
                              size={`large`}
                              onClick={() => {
                                showDiagramData(diagram?.id);
                              }}
                            />
                          </Tooltip>
                        )}
                      {checkRole("update") && (
                        <Tooltip title="Edit Title/Description">
                          <Button
                            type="text"
                            icon={<EditOutlined />}
                            size={`large`}
                            onClick={() => {
                              setEditDiagramTitle(diagram);
                            }}
                          />
                        </Tooltip>
                      )}
                      {checkRole("delete") && (
                        <Tooltip title="Delete Diagram">
                          <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            size={`large`}
                            onClick={() => {
                              deleteDiagram(diagram?.id);
                            }}
                          />
                        </Tooltip>
                      )}
                    </Flex>
                  </Flex>
                </div>
              );
            }) || ""}
          </div>
          <div className="d-flex justify-content-between my-2">
            {checkRole("add") && (
              <AddDiagram
                setEditDiagramTitle={setEditDiagramTitle}
                editDiagramTitle={editDiagramTitle}
                businessApplicationId={modelId}
                moduleName={moduleName}
                onListUpdate={getDiagrams}
                titleName={titleName}
                portfolioSetting={portfolioSetting}
                scenarioId={scenarioId}
                refreshTab={refreshTab}
                setrefreshTab={setrefreshTab}
                
              />
            )}
          </div>
          <hr />
          <div className="d-none">
            <div className="h4 mb-3">
              <span style={{ color: "#ffc107" }}>"{titleName}"</span> is
              referenced in following diagrams
            </div>
            <div className={`card card-body p-0`} key={Math.random()}>
              <div className="d-flex justify-content-between align-items-center">
                <div
                  type={`text`}
                  className="w-100 p-1 cursor-pointer"
                  onClick={() => {}}
                >
                  <div className="w-100 d-flex">
                    <div
                      style={{
                        width: 40,
                        borderRight: "1px solid #000",
                        textAlign: "center",
                      }}
                      className="px-2"
                    >
                      {1}
                    </div>
                    <div className="text-left w-100 px-2">Online Payment</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`card card-body p-0`} key={Math.random()}>
              <div className="d-flex justify-content-between align-items-center">
                <div
                  type={`text`}
                  className="w-100 p-1 cursor-pointer"
                  onClick={() => {}}
                >
                  <div className="w-100 d-flex">
                    <div
                      style={{
                        width: 40,
                        borderRight: "1px solid #000",
                        textAlign: "center",
                      }}
                      className="px-2"
                    >
                      {1}
                    </div>
                    <div className="text-left w-100 px-2">Refund</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={1} className="text-center">
          <Divider
            type="vertical"
            style={{ height: window?.innerHeight - 300 }}
            plain
          />
        </Col>
        <Col span={12} className="p-0">
          {getImageScreen()}
        </Col>
      </Row>
      <Row>
        <Col span={24} className="p-0">
          {getDataDigramForm()}
        </Col>
      </Row>
      <Drawer
        open={fullscreen}
        onClose={() => {
          setFullscreen(!fullscreen);
        }}
        title={false}
        closeIcon={false}
        destroyOnClose={true}
        width={window?.innerWidth}
        height={window?.innerHeight}
        placement={`top`}
        styles={{body:{padding:0}}}
      >
        {getImageScreen()}
      </Drawer>
      <ImportApplicationData
        open={openImportModal}
        setOpen={setOpenImportModal}
        // setOpenPDF={setOpenPDFModal}
        // openPDF={openPDFModal}
        // setIsImportInProgress={setIsImportInProgress}
        // isImportInProgress={isImportInProgress}
        name={"Diagram"}
        diagramFileName = {fileName}
        saveDiagramBpmn = {setSaveDiagramBpmn}
        diagramId={showDiagramId}
        isDiagram={true}
        showDiagramData={showDiagramData}
        {...props}
      />
    </div>
  );
};

DiagramScreen.propTypes = {};

export default DiagramScreen;
