import { ExclamationCircleFilled, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Carousel, Modal, Spin, message } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import BPMLevel1 from './grid/BPMLevel1';
import BusinessProcessServices from '../../services/services/BusinessProcessServices';
import { COLORS } from '../../utils/Common';
import { UpdateChangeLog } from '../../utils/ChangeLogs';
import LoadingBar from 'react-top-loading-bar'
import { useLocation } from 'react-router';
import ApqcProcessServices from '../../services/services/ApqcProcessServices';
import BusinessProcessHeader from './BusinessProcessHeader';

const SampleNextArrow = (props) => {
    const { className, style, onClick,currentSlide,slideCount } = props;
    return (
        <div
            className={className}
            style={{ ...style,cursor:((currentSlide+window.innerWidth/500)===slideCount?`initial`:'pointer'),position:"fixed",top:window?.innerHeight/3,right:20,zIndex:100 }}
            onClick={onClick}
            disabled={currentSlide===0}
        >
            <RightCircleOutlined style={{ fontSize: 45,color: ((currentSlide+window.innerWidth/500)===slideCount?`#ddd`:'#000')}}/>
        </div>
    );
}

const SamplePrevArrow = (props) => {
    const { className, style, onClick,currentSlide } = props;
    return (
        <div
            className={className}
            style={{ ...style,cursor:(currentSlide===0?`initial`:'pointer'),position:"fixed",top:window?.innerHeight/3,left:5,zIndex:100 }}
            onClick={onClick}
            disabled={currentSlide===0}
        >
            <LeftCircleOutlined style={{ fontSize: 45,color: (currentSlide===0?`#ddd`:'#000')}}/>
        </div>
    );
}

const BusinessProcessGrid = ({ HeaderContnet, moduleName, ...props }) => {
    const ref = useRef(null)
    const [loading, setLoading] = useState(false);
    const [lists, setLists] = useState([]);
    const location = useLocation();
    const [bcm2Index, setBcm2Index] = useState(null)



    const getProcessList = () => {
        if (moduleName === "BusinessProcesses") {
            return BusinessProcessServices
        } else if (moduleName === "ApqcsProcesses") {
            return ApqcProcessServices
        }
    }

    useEffect(() => {
        getBusinessProcessList();
    }, [])

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: window.innerWidth/500,
        slidesToScroll: 1,
        initialSlide: 0,
        swipeToSlide: true,
        draggable: true,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const sortBPMData = (lists) => {
        return lists?.sort((a, b) => {
            return a?.LevelID?.localeCompare(b?.LevelID, undefined, { numeric: true, sensitivity: 'base' });
        })
    }

    const getBusinessProcessList = async (filter_By, filter_ByText, filter_levels, filterOrder) => {
        setLoading(true);
        if (ref.current) {
            ref.current.continuousStart();
        }
        try {
            const list = await getProcessList().getList(filter_By, filter_ByText, filter_levels, filterOrder);
            const resultData = list?.data?.data
            setLists(resultData);
            setLoading(false);
            if (ref.current) {
                ref.current.complete();
            }
        } catch (error) {
            setLoading(false);
            if (ref.current) {
                ref.current.complete();
            }
        }
    }

    const showConfirmDelete = (item, type) => {
        const id = item?.id || 0;
        Modal.confirm({
            title: 'Are you sure want to delete?',
            icon: <ExclamationCircleFilled />,
            async onOk() {
                try {
                    let result;
                    result = await getProcessList().deletebusinessProcess(id);
                    if (result) {
                        let obj = {
                            id: id,
                            NameInSourceSystem: item?.NameInSourceSystem || "",
                            DisplayName: item?.DisplayName || "",
                            Description: item?.Description || "",
                            LevelID: item?.LevelID || "",
                        }

                        let field_OldData = {
                            DisplayName: item?.DisplayName || "",
                            Description: item?.Description || "",
                            LevelID: item?.LevelID || "",
                        }


                        await UpdateChangeLog(obj.id, field_OldData, obj, "Delete", moduleName)

                        await getBusinessProcessList();
                        message.success(result?.data?.msg);
                    } else {
                        message.error(result?.data?.msg)
                    }
                } catch (error) {
                    message.error(error?.message)
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const create_Duplicate = async (obj) => {
        console.log('testing obj data>>>>>>>>>..', obj);

        try {

            let result = await getProcessList().create(obj)

            if (result.data?.code == "200") {
                getBusinessProcessList()
                setBcm2Index(null)
            }

        } catch (error) {
            console.log(error);

        }
    };


    return (
        <div className='container-fluid w-100'>
            <LoadingBar color='#1f1f1f' ref={ref} />
            <Spin className="loading_bx" size='small' spinning={loading}>
                {HeaderContnet}

                <div className='w-100'>
                    <div style={{ margin: 24 }} className="slider-container">
                        <Carousel {...settings}>
                            {
                                sortBPMData(lists).map((item, index) => {
                                    let color = COLORS[parseInt(index % COLORS.length)];
                                    return <div key={index} className='align-items-start text-center'><BPMLevel1 index={index} backgroundColor={color} setItemDelete={showConfirmDelete} openModal={props.openModal} visibleModal={props.visibleModal} setModalData={props.setModalData} data={item} basePath={location?.pathname} create_Duplicate={create_Duplicate} bcm2Index={bcm2Index} setBcm2Index={setBcm2Index} /></div>
                                })
                            }
                        </Carousel>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

BusinessProcessGrid.defaultProps = {}

export default BusinessProcessGrid