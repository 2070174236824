import { Button, Dropdown, Flex, Spin, Tooltip } from 'antd';
import React, { useEffect, useState,useRef } from 'react';
import { useLocation } from 'react-router';

import { GoBack } from '../../utils/GoBackHistory';

import BpmnModeler from 'bpmn-js/lib/Modeler';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-font/dist/css/bpmn-embedded.css';
import { emptyBpmn } from '../../assets/empty.bpmn';
import { DownloadOutlined, FileImageOutlined, FileOutlined, UploadOutlined } from '@ant-design/icons';
import { exportContent, getDiagram, getTitleData, handleFileSelect, saveDiagram } from './BpmnEditorFn';

const Bpmneditorv2 = ({diagramId,...props}) => {
    const [loading,setLoading] = useState(false);
    const location = useLocation();
    const [diagramData,setDiagramData] = useState({});
    const [previousPath,setPreviousPath] = useState("");
    const [moduleData,setModuleData] = useState({}); 
    const containerRef = useRef(null); // Reference to the container for the modeler
    const propviewRef = useRef(null); // Reference to the container for the modeler
    const modelerRef = useRef(null); // Reference to the BPMN modeler instance
    const fileInputRef = useRef(null);

    useEffect(() => {
        modelerRef.current = new BpmnModeler({
            container: containerRef.current,
            keyboard: {
                bindTo: window
            },
            propertiesPanel: {
                parent: propviewRef.current
            },
            additionalModules: [
            ],
            moddleExtensions: {
            }
        });

        if(diagramId){
            getDiagram({setLoading,diagramId,setDiagramData});
            if(location?.state?.previousPath){
                sessionStorage.setItem("bpmnioPreviousPath", location?.state?.previousPath || "");
                sessionStorage.setItem("bpmnioPreviousModuleData",JSON.stringify( location?.state) || {});
                setPreviousPath(location?.state?.previousPath);
                setModuleData(location?.state)
            }else{
                setPreviousPath(sessionStorage.getItem("bpmnioPreviousPath"));
                setModuleData(JSON.parse(sessionStorage.getItem("bpmnioPreviousModuleData")))
            }
        }

        // Cleanup on component unmount
        return () => {
            if (modelerRef.current) {
                modelerRef.current.destroy(); // Destroy the modeler instance
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[diagramId])

    useEffect(()=>{
        if(diagramData?.xml){
            openBpmnDiagram(diagramData?.xml)
        }else{
            openBpmnDiagram(emptyBpmn)
        }
    },[diagramData])

    

    const openBpmnDiagram = (xml) => {
        modelerRef.current.importXML(xml, (err) => {
            if (err) {
            console.error('Error importing BPMN diagram:', err);
            } else {
            console.log('BPMN diagram imported successfully!');
            }
        });

        // Cleanup on component unmount
        return () => {
            if (modelerRef.current) {
                modelerRef.current.destroy(); // Destroy the modeler instance
            }
        };
    }
    
    const ExportItems = [
        {
            key: '1',
            label: (
              <Tooltip title={`Export Image`}><a href="#/" onClick={()=>{ exportContent({type:'svg',modelerRef,name:diagramData?.name}); }} rel="noopener noreferrer"><FileImageOutlined /> SVG</a></Tooltip>
            ),
          },
          {
            key: '2',
            label: (
              <Tooltip title={`Export BPMN`}><a href="#/"   onClick={()=>{ exportContent({type:'bpmn',modelerRef,name:diagramData?.name}); }} rel="noopener noreferrer"><FileOutlined /> BPMN</a></Tooltip>
            ),
        },
    ];

    return (
        <>
            <Spin className="loading_bx" size="small" spinning={loading}>
                <div className='container-fluid'>
                    { 
                        diagramData?.id && 
                        <Flex gap={8} align='center' justify='space-between' className='headingbx'>
                            {getTitleData({diagramData,moduleData})}
                            <Flex gap={8} justify='flex-end' align="center">
                                <Button onClick={()=>{ saveDiagram({modelerRef, setLoading, diagramId, diagramData}); }} type="primary">Save</Button>
                                <Dropdown
                                    menu={{
                                        items:ExportItems,
                                    }}
                                    placement="bottom"
                                >
                                    <Tooltip title="Export BPMN">
                                        <Button icon={<DownloadOutlined />}/>
                                    </Tooltip>
                                </Dropdown>
                                <Tooltip title="Import BPMN">
                                    <Button 
                                        onClick={() => { 
                                            if (fileInputRef.current) {
                                                fileInputRef.current.click();
                                            }
                                        }} 
                                        icon={<UploadOutlined />}
                                    />
                                </Tooltip>
                                <GoBack path={previousPath || -1} state={{activeTabKey:'tab_diagram'}} />
                            </Flex>
                        </Flex>
                    }
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        hidden={true}
                        accept=".bpmn"
                        onChange={(event)=>{ handleFileSelect({event,setLoading,openBpmnDiagram}) }}
                    />
                    <div id="bpmncontainer" >
                        <div id="propview" style={{ width: '25%', height: window?.innerHeight-150, float: 'right', overflowX: 'auto', display: 'none' }} ref={propviewRef}></div>
                        <div id="bpmnview" style={{ width: '100%', height: window?.innerHeight-150, float: 'left' }} ref={containerRef}></div>
                    </div>
                </div>
            </Spin>
        </>
    )
}

export default Bpmneditorv2
