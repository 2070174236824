import React, { useCallback, useEffect, useState } from "react";
import "./SideBar.scss";
import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router";
import { useMenu } from "../../../Context/MenuProvider";
import { GoBackHistory } from "../../../utils/GoBackHistory";
import { MenuOutlined } from "@ant-design/icons";

const SideBar = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [menuWidth, setMenuWidth] = useState(230);
  const [menus, setMenus] = useState([]);
  const location = useLocation();
  let navigate = useNavigate();
  const [current, setCurrent] = useState("scenarios");
  const [openMenusProps, setMenusProps] = useState(null);
  const { stateMenu, dispatchMenu } = useMenu();
  const [newKey, setNewKey]=useState([])
  
  useEffect(()=>{
    GoBackHistory(location);
  },[location])
  
  useEffect(() => {
    if (props?.menus) {
      setMenus(props?.menus); 
    }
  }, [props?.menus]);

  useEffect(() => {
    if (menus?.length > 0) {
      setCurrent(location?.pathname.replace("/", ""));
      let openSubkeyName = location?.pathname.split("/")[1];
      let okName = menus.find((f) => {
        if (f?.children?.find((f1) => f1.key === openSubkeyName)) {
          return true;
        } else {
          return false;
        }
      });

      if (okName?.children?.length > 0) {
        setMenusProps({
          defaultOpenKeys: [okName.key],
          openKeys: [okName.key],
          defaultSelectedKeys: [openSubkeyName],
          selectedKeys: [openSubkeyName],
        });
      } else {
        setMenusProps({
          defaultOpenKeys: [],
          openKeys: [],
          defaultSelectedKeys: [openSubkeyName],
          selectedKeys: [openSubkeyName],
        });
      }
    }
  }, [menus]);

  const onClick = async (e) => {
    setCurrent(e.key);
    navigate("/" + e.key);
    
    let menuObj= getMenu( menus, e.key);
    if (e.keyPath[1] === "landscape-scenario" ){
      setMenusProps({
        defaultOpenKeys: ["landscape-scenario"],
        openKeys: ["landscape-scenario","others"],
      });
    }else{
    setMenusProps({
      defaultOpenKeys: newKey,
      openKeys: [e.keyPath[1]],
    });
    }

    console.log('testing sidebar',{
      defaultOpenKeys: newKey,
      openKeys: [e.keyPath],
    } );
    
    await dispatchMenu({
      type: "MenuDetail",
      payload: { Name: e?.key, MenuName: menuObj?.label, ID: menuObj?.moduleID, moduleName:menuObj?.moduleName,  color: menuObj?.color },
    });
  };

  const onOpenChange = (openKeys) => {
    // Close all other keys except the one being opened
    setNewKey(openKeys)
    setMenusProps({
      defaultOpenKeys: newKey,
      openKeys: openKeys,
    });
  };

  const getMenu = (SideMenuItems, key) => {
    let okName = SideMenuItems.find((f) => {
      if (f?.children?.find((f1) => f1.key === key)) {
        return true;
      } else {
        return false;
      }
    });

    let bcItem;
    if (okName?.children?.length > 0) {
      okName?.children?.map((item) => {
        if (item.key === key) {
          bcItem = item;
        }
      });
    } else {
      SideMenuItems?.map((item) => {
        if (item.key === key) {
          bcItem = item;
        }
      });
    }
    return bcItem;
  };

  return (
    <React.Fragment>
      <Menu
        defaultOpenKeys={openMenusProps?.defaultOpenKeys}
        openKeys={openMenusProps?.openKeys}
        defaultSelectedKeys={openMenusProps?.defaultSelectedKeys}
        selectedKeys={openMenusProps?.selectedKeys}
        // mode="inline"
        mode="horizontal"
        theme="dark"        
        onClick={onClick}
        onOpenChange={onOpenChange}
        // collapsed={collapsed}
        items={menus?.map(item=>{  
            item.navigate = "false";
            if(item?.children){
              item.children = item?.children?.map(child=>{
                child.navigate = "false";
                return child;
              })
            }
            return item;
          })
        }
        style={{
          // width:`100%`
          // transition: "width 1s",
          // minHeight: window.innerHeight,
          // height: "100%",
          // paddingBottom:"100px",
        }}
        overflowedIndicator={<MenuOutlined />}
      />
    </React.Fragment>
  );
};

export default SideBar;
