import "./../components/portfolio/application/BusinessApplication.scss";
import React, { useEffect, useState } from 'react'
import BusinessApplicationGrid from '../components/portfolio/application/BusinessApplicationGrid'
import BusinessProcessHeader from "../components/businessProcess/BusinessProcessHeader";
import { elementToSVG, inlineResources } from 'dom-to-svg'
import { Button, Dropdown, Input, message, Modal, Popover, Tooltip, Typography } from "antd";
import { DownloadOutlined, FileImageOutlined, FilePdfOutlined, InfoCircleOutlined, NodeExpandOutlined } from "@ant-design/icons";
import { PageTitleHeading } from "../utils/Common";
import ReportsSaveAs from "../components/reports/ReportsSaveAs";
import { useSearchParams } from "react-router-dom";
import PersonalServices from "../services/services/PersonalServices";
import { getMenuPortfolio } from "../utils/settingCommon";
var htmlPdfClient = require("html-pdf-client")

const ReportsPage = ({heading,moduleName,...props}) => {
    const contentRef = React.useRef();
    const [showFullContent,setShowFullContent] = useState(false);
    const [showEmbedDiagramModal,setShowEmbedDiagramModal] = useState(false);
    const [reportFilter,setReportFilter] = useState({});
    const [reportPageFilter,setReportPageFilter] = useState(null);
    const [iframeValue, setIframeValue] = useState(``);
    const [exportDisabled, setExportDisabled] = useState(true);
    const [saveReport, setSaveReport] = useState(false);
    const [reportData, setReportData] = useState({});
    const [searchParams] = useSearchParams();
    const [reportId,setReportId] = useState(null);
    const [loginUserEmail] = useState(localStorage.getItem('email'));
    const exportContentImg =async (type="") => {
        try {
            setShowFullContent(true)
            let svgString;
            await new Promise(resolve => setTimeout(resolve, 500));
            setExportDisabled(false);
            if(moduleName==="Integration"){
                const svgUrl = document.querySelector('.integration-landscape').querySelector('.planuml-div object').data;
                var xmlHttp = null;
                console.log("svgUrl: ",svgUrl);
                xmlHttp = new XMLHttpRequest();
                xmlHttp.open( "GET", svgUrl, false );
                xmlHttp.send( null );
                svgString = xmlHttp.responseText;
            }else if(moduleName==="BusinessApplication"){
                const svgDocument = elementToSVG(document.querySelector('.integration-landscape'))

                // Inline external resources (fonts, images, etc) as data: URIs
                await inlineResources(svgDocument.documentElement)

                // Get SVG string
                svgString = new XMLSerializer().serializeToString(svgDocument);
            }else {
                // if(moduleName==="Technology"){
                const svgDocument = elementToSVG(document.querySelector('.integration-landscape'))

                // Inline external resources (fonts, images, etc) as data: URIs
                await inlineResources(svgDocument.documentElement)

                // Get SVG string
                svgString = new XMLSerializer().serializeToString(svgDocument);
            }

            var svgBlob = new Blob([svgString], {type:"image/svg+xml;charset=utf-8"});
            var svgUrl = URL.createObjectURL(svgBlob);
            if(type==="pdf" && moduleName==="Integration"){
                let downloadLink = document.createElement("a");
                let url = document.querySelector('.integration-landscape').querySelector('.planuml-div object').data;
                downloadLink.href = url.replace("https://plantuml.truerouta.com/svg/","https://plantuml.truerouta.com/pdf/");
                downloadLink.download = `${heading}.pdf`;
                downloadLink.setAttribute('target', '_blank');
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }else if(type==="pdf"){
                await new Promise(resolve => setTimeout(resolve, 500)); 
                const svgElement = document.querySelector('.integration-landscape');
                const title = document.querySelector('.integration-landscape .title-headtag').innerText;
                if(document.querySelector('.acg-table')){
                    document.querySelector('.integration-landscape .acg-table').classList.add('acg-table-print');
                } 

                var opt = {
                    filename:     `${title}.pdf`,
                    image:        { type: 'jpeg', quality: 0.98 },
                    html2canvas:  { scale: 2, allowTaint: false, useCORS: true, optimized: false, letterRendering: true, logging: true},
                    jsPDF:        { unit: 'cm', format: 'A4' , orientation: 'landscape',compressPDF: true ,},
                    margin: [0.1, 0.2],
                    pagebreak:    {  after: ['.page-break'] , avoid: ['tr', 'td']},//, avoid: ['tr', 'td']
                    // pagebreak:    { before: '.page-break' }
                };
                await htmlPdfClient().set(opt).from(svgElement).toPdf().save();
                if(document.querySelector('.acg-table')){
                    document.querySelector('.integration-landscape .acg-table').classList.remove('acg-table-print');
                }
            }else{
                let downloadLink = document.createElement("a");
                downloadLink.href = svgUrl;
                downloadLink.download = heading+".svg";
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
            
            setShowFullContent(false)
        } catch (error) {
            setShowFullContent(false)
        } finally{
            setShowFullContent(false)
            
        }
    }

    useEffect(()=>{
        setReportFilter({})
        setReportPageFilter(null)
        setReportId(searchParams?.get("id"));
    },[searchParams])

    useEffect(()=>{
        getIframeValue();
        if(reportId){ getReportData(reportId) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reportId])

    const getIframeValue = () =>{
        let url = `${process.env.REACT_APP_API_URL}portfoliosetting/embed/IntegrationLandscapeView?search=`;
        if(!reportFilter?.isIntegrationLandscape){
            url = `${window?.location?.origin}/iframe-application-landscape?search=`;
        }
        
        url+=encodeURI(JSON.stringify(reportFilter));
        // console.log("url",url);
        
        // url+=JSON_to_URLEncoded(reportFilter);
        setIframeValue(`<iframe src="${url}" avoidlogin="true" style="margin:0px;padding:0px;border:0px solid #666666;"></iframe>`)
    }

    const dropdownItems = [
        {
          key: '1',
          label: (
            <Tooltip title={`Export Image`}><a href="#/" onClick={()=>{ exportContentImg(); }} rel="noopener noreferrer"><FileImageOutlined /> SVG</a></Tooltip>
          ),
        },
        {
          key: '2',
        //   disabled: moduleName==="Integration"? true:false,
        //   hidden: moduleName==="Integration"? true:false,
          label: (
            <Tooltip title={`Export PDF`}><a href="#/"   onClick={()=>{ exportContentImg("pdf"); }} rel="noopener noreferrer"><FilePdfOutlined /> PDF</a></Tooltip>
          ),
        },
    ];

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                // console.log('Mutation detected:', mutation);
                if(mutation?.type ==="childList" && mutation?.target?.querySelector('.title-headtag')){
                    setExportDisabled(false);
                }else{
                    // setExportDisabled(true);
                }
            });
        });

        if (contentRef.current?.querySelector('.integration-landscape')) {
            observer.observe(contentRef.current?.querySelector('.integration-landscape'), {
                attributes: true, // Observe attribute changes
                childList: true, // Observe addition/removal of child nodes
                // subtree: true, // Observe all descendants
                // characterData: true // Observe changes to text content
            });
        }

        // Cleanup the observer on component unmount
        return () => {
            observer.disconnect();
            setExportDisabled(true);
        };
    }, [reportFilter?.moduleName,moduleName]);

    const getReportData = async (id) => {
        try {
            const {data:response} = await PersonalServices?.getReport(id);
            if(response?.payload){
                setReportPageFilter(response?.payload); 
                setReportData(response)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const Buttons = <React.Fragment>
        {reportId && reportData?.useremail===loginUserEmail && <Tooltip title={'Update'}><Button onClick={()=>{ setSaveReport('update') }} >Update</Button></Tooltip>}
        <Tooltip title={'Save As'}><Button onClick={()=>{ setSaveReport('new') }} disabled={exportDisabled}>Save As</Button></Tooltip>
        <Dropdown menu={{ items:dropdownItems }} placement="bottomLeft" disabled={exportDisabled}>
            <Button icon={<DownloadOutlined />}></Button>
        </Dropdown>

        {/* <Tooltip title={`Export Image`}><Button icon={<DownloadOutlined />} onClick={()=>{ exportContentImg(); }}></Button></Tooltip> */}
        <Tooltip title={`Embed Diagram`}><Button icon={<NodeExpandOutlined />} onClick={()=>{ setShowEmbedDiagramModal(!showEmbedDiagramModal); }} disabled={exportDisabled}></Button></Tooltip>
        &nbsp;
    </React.Fragment>
    
    return (
        <React.Fragment>
            <div className='business-application-page' ref={contentRef}>
                {moduleName && <BusinessApplicationGrid
                    HeaderContent={
                        <BusinessProcessHeader 
                            activePage={1}
                            setActivePage={()=>{}}
                            heading={<Heading heading={heading}/>}
                            matrix={false}
                            grid={false}
                            list={false}
                            addBtn={false}
                            bulkDelete={false}
                            bulk_delete={false}
                            showActionBtn={false}
                            Buttons = {Buttons}
                        />
                    }
                    heading={heading}
                    showFullContent={showFullContent}
                    moduleName={moduleName}
                    activePage={1}
                    setReportFilter={setReportFilter}
                    reportFilter={reportFilter}
                    reportPageFilter={reportPageFilter}
                    {...props}
                />}
            </div>
            <Modal
                open={showEmbedDiagramModal}
                onCancel={()=>{ setShowEmbedDiagramModal(!showEmbedDiagramModal); }}
                footer={false}
                afterOpenChange={(v)=>{ if(v){ getIframeValue(); } }}
            >
                <PageTitleHeading text={`Embed Diagram`} className={`mb-2`}/>
                <hr/>
                <Input.TextArea
                    value={iframeValue}
                    onChange={(e) => {
                        setIframeValue(e.target.value);
                    }}
                    placeholder="Embed Diagram Iframe"
                    readOnly
                    autoSize={{
                        minRows: 3,
                        maxRows: 5,
                    }}
                />
                <Button
                    type="default"
                    className="mt-2"
                    onClick={() => {
                        navigator.clipboard.writeText(iframeValue);
                        message.success("Copied...");
                    }}
                >
                    Copy
                </Button>
            </Modal>
            {saveReport && <ReportsSaveAs 
                reportData={saveReport==='update' ? reportData : null}
                setReportData={setReportData}
                id={reportId}
                type={`portfolio`}
                moduleName={moduleName}
                reportFilter={reportFilter}
                visible={saveReport}
                setVisible={setSaveReport}
            />}
        </React.Fragment>
    )
}

ReportsPage.defaultProps = {
    showColorIndicator:false
}

export default ReportsPage

export const JSON_to_URLEncoded = (element,key,list)=>{
    list = list || [];
    if(typeof(element)=='object'){
      for (var idx in element)
        JSON_to_URLEncoded(element[idx],key?key+'['+idx+']':idx,list);
    } else {
      list.push(key+'='+encodeURIComponent(element));
    }
    return list.join('&');
}

export const Heading = ({heading}) => {
    const content = <div style={{maxWidth:window?.innerWidth/2}}>
        <h4 className="text-dark pb-0 fs-5">Welcome to the {heading}!</h4>
        <p  className="text-dark pb-0">This tool helps you gain valuable insights into business capability realization within your organization. Explore and answer critical questions like:</p>
        <ol style={{all:'revert'}} className="mt-1">
            <li><span className="h4 text-dark">Organizational Overview:</span> What are the core business capabilities of my organization?</li>
            <li><span className="h4 text-dark">Strategic Alignment:</span> Which business capabilities are required or impacted to achieve strategic objectives?</li>
            <li><span className="h4 text-dark">Data and Information Needs:</span> What data or information is essential to execute a business capability effectively?</li>
            <li><span className="h4 text-dark">Departmental Ownership:</span> Which departments or teams are responsible for managing specific business capabilities?</li>
            <li><span className="h4 text-dark">Processes and Workflows:</span> What processes are in place to support and perform business capabilities?</li>
            <li><span className="h4 text-dark">Initiatives and Impact:</span> Which initiatives are driving changes or impacting specific business capabilities?</li>
            <li><span className="h4 text-dark">Interconnectivity:</span> How are different business capabilities interconnected?</li>
            <li><span className="h4 text-dark">Capability Maturity:</span> What is the current maturity level of critical business capabilities? How prepared are we to realize these capabilities?</li>
            <li><span className="h4 text-dark">Investment and Improvement Areas:</span> What areas of the organization require investment or improvement to strengthen capabilities?</li>
            <li><span className="h4 text-dark">Technical Support:</span> What technical capabilities are critical for efficient business capability realization?</li>
            <li><span className="h4 text-dark">Cost Insights:</span> What are the most expensive business capabilities, and how can they be optimized?</li>
        </ol>
    </div>

    return <>
        {heading}&nbsp;
        <Popover placement="bottomLeft" content={content}>
            <InfoCircleOutlined className="pointer" />
        </Popover>
        
    </>
}