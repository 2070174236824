import HomeV3 from "../components/home/HomeV3";

function HomePage(props) {

    return (
        <div className="vp">
            <HomeV3 {...props} />
        </div>
    );
}

export default HomePage;