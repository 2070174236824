import { encodeQueryData, objectToQueryParams } from "../../utils/Common";
import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;
// const API_BASE_URL = 'http://localhost:3000/api/v1/'

async function ImportASIS(obj) {
  const url = `${API_BASE_URL}new-scenario`;
  let response = await http.post(url, obj);
  return response;
}

async function Delete(obj) {
  const url = `${API_BASE_URL}new-scenario/delete`;
  let response = await http.post(url, obj);
  return response;
}

async function CheckModified(obj) {
  const url = `${API_BASE_URL}new-scenario/check-modified`;
  let response = await http.post(url, obj);
  return response;
}

async function BulkcreateOnImportFromOtherScenario(obj) {
  const url = `${API_BASE_URL}new-scenario/importfromotherscenario`;
  let response = await http.post(url, obj);
  return response;
}

async function BulkcreateOnImportFromOtherScenarioSelectedItems(obj) {
  const url = `${API_BASE_URL}new-scenario/importfromotherscenario-selectedids`;
  let response = await http.post(url, obj);
  return response;
}

async function addItem(obj) {
  const url = `${API_BASE_URL}new-scenario/addItem`;
  let response = await http.post(url, obj);
  return response;
}

const NewScenarioServices = {
  ImportASIS,
  Delete,
  CheckModified,
  BulkcreateOnImportFromOtherScenario,
  BulkcreateOnImportFromOtherScenarioSelectedItems,
  addItem
};

export default NewScenarioServices;
