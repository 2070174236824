import {transformText } from "../utils/Common";

const bgColors = [
  "#ffb484",
  "#fff484",
  "#c0ec84",
  "#ffbce4",
  "#c0f4e4",
  "#e0b4fc",
  "#88ace4",
  "#58bcbc",
];
const transformData = (inputData, bgColors = bgColors, portfolioList) => {

  // console.log(inputData, portfolioList)
  
  const colors = bgColors;
  let colorIndex = 0;
  //Child  

   const transformChildren = (children, parentTitle, colorChildren) => {
    return children.map((child) => {
 
      const obj = {
        //title:child.moduleName || child.displayName || child.nameInSourceSystem,
        title:child.moduleName || child.displayname  ,
        //background: colors[colorIndex], // Assign color from the colors array
        background: colorChildren,
        children:
          child.tags && child.tags.length > 0
            ? transformChildren(child.tags, child.moduleName,colorChildren)
            : [],
        data: { ...child }, // Retain the data field
        parentTitle: parentTitle,
      };
      if(child?.id){
        obj.id = child?.id;
      }
      colorIndex = (colorIndex + 1) % colors.length; // Move to the next color in the array
      return obj;
    });
  };

  const transformRelations = (relations, parentTitle) => {
    //Parent
     
    return relations.map((relation) => {
      
      const obj = {
        // title:
        //   relation.moduleName ||
        //   relation.displayName ||
        //   relation.nameInSourceSystem,
          title:
          relation.moduleName ||
          relation.displayname  ,

        //background: colors[colorIndex],
        background: getPortfolioColor(relation.moduleName ,portfolioList), // Portfolio color 
        children:
          relation.tags && relation.tags.length > 0
            ? transformChildren(relation.tags, relation.moduleName, getPortfolioColor(relation.moduleName ,portfolioList))
            : [],
        data: { ...relation }, // Retain the data field
        parentTitle: parentTitle,
      };
      colorIndex = (colorIndex + 1) % colors.length; // Move to the next color in the array
      return obj;
    });
  };

  const transformedData = [];

  //Root 
  // Transform the relations
  //const rootTitle = inputData.displayName || inputData.nameInSourceSystem;
  const rootTitle = inputData.displayname  ;
  const relations =
    inputData.relations && inputData.relations.length > 0
      ? transformRelations(inputData.relations, rootTitle) // Pass null as parentTitle for top-level relations
      : [];

  const rootObj = {
    title: rootTitle,
    parentTitle: inputData?.nodeTag,
    background: getPortfolioColor(inputData.nodeTag ,portfolioList), // Assign color from the colors array
    // children:
    //   inputData.relations && inputData.relations.length > 0
    //     ? transformRelations(inputData.relations)
    //     : [],
    children: relations,
    data: { ...inputData }, // Retain the data field for the root object
  };

  transformedData.push(rootObj);

  if(inputData?.child?.length>0){
    let childrenObj = {
      title: "Children",
      background: "#CDDC39",
      children: inputData?.child?.map(child=>{
        return {
          "id": child?.id,
          "title": child?.displayname,
          "background": "#CDDC39",
          "children": [],
          "parentTitle": inputData?.nodeTag,
          "data": child,
        }
      }),
      parentTitle: inputData?.nodeTag,
    };
    transformedData[0].children = [childrenObj,...transformedData[0]?.children || []]
  }

  if(inputData?.parent?.length>0){
    let parentObj = {
      title: "Parent",
      background: "#ff7f57",
      children: inputData?.parent?.map(par=>{
        return {
          "id": par?.id,
          "title": par?.displayname,
          "background": "#ff7f57",
          "children": [],
          "parentTitle": inputData?.nodeTag,
          "data": par,
        }
      }),
      parentTitle: inputData?.nodeTag,
    };
    transformedData[0].children = [parentObj,...transformedData[0]?.children || []]
  }

  // if(inputData?.parent?.length>0){
  //   const parentObj = inputData?.parent?.map(parent=>{
  //     return {
  //       ...parent,
  //       parentTitle: inputData?.nodeTag,
  //       title: parent?.displayname,
  //       background: "#ff7f57",//getPortfolioColor(inputData.nodeTag ,portfolioList),
  //       children: transformedData,
  //       data: { ...inputData },
  //     }
  //   })
  //   return parentObj;
  // }

  return transformedData;
};

const getNodesByModuleName = (array, moduleName) => {
  return array?.find((item) => item?.moduleName === moduleName) || {};
};

const convertToNormalText = (str) => {
  let text;
  if (str?.includes("_")) {
    text = str
      ?.split("_")
      ?.map(
        (word) => word.charAt(0)?.toUpperCase() + word.slice(1)?.toLowerCase()
      )
      ?.join(" ");
  // } else if (str?.includes("-")) {
  //   text = str
  //     ?.split("-")
  //     ?.map(
  //       (word) => word.charAt(0)?.toUpperCase() + word.slice(1)?.toLowerCase()
  //     )
  //     ?.join(" ");
  }
  else {
    text = str?.replace(/([a-z])([A-Z])/g, "$1 $2");
    //text = text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase();
  }
  return text;
};

const dynamicRoutesLinks = [
  {
    moduleName: "BusinessApplication",
    link: "/portfolio/",
    type: "business_application"
  },
  {
    moduleName: "BusinessCapability",
    link: "/business-capability-master/",
    type: "business_capability"
  },
  {
    moduleName: "BusinessProcesses",
    link: "/business-process/",
    type: "business_processes"
  },
  {
    moduleName: "BusinessProcesses",
    link: "/business-process/",
    type: "business_processes"
  },
  // {
  //   moduleName: "Initiative",
  //   link: "/portfolio-initiative/",
  //   type: "initiative"
  // },
  {
    moduleName: "Product",
    link: "/portfolio-product/",
    type: "business_application"
  },
  {
    moduleName: "ProductArea",
    link: "/portfolio-product-area/",
    type: "business_application"
  },
  {
    moduleName: "ProductCluster",
    link: "/portfolio-product-cluster/",
    type: "business_application"
  },
  {
    moduleName: "integration",
    link: "",
    type:"integration"
  },
  {
    moduleName: "Plant",
    link: "/portfolio/",
    type:"plant"
  },
  {
    moduleName: "Dataobject",
    link: "/portfolio/",
    type:"Dataobject"
  },
  {
    moduleName: "Informationobject",
    link: "/portfolio/",
    type:"Informationobject"
  }

];

const getDynamicRoutesLink = ({type,moduleName,id,path}) => {
  let redirectLink = dynamicRoutesLinks?.find(
    (item) => (item?.type === type || item?.moduleName === moduleName)
  );
  
  if (redirectLink?.link && redirectLink?.moduleName && id) {
    redirectLink = redirectLink?.link + redirectLink?.moduleName + "/" + id;
    return redirectLink;
  }
  
  if(path && id){
    return path+"/"+id;
  }
  return '/';
}

const getPortfolioColor=(strPortfolio, portfolioList)=>{
 
  const color= portfolioList?.find((element) => {
    return transformText( element?.moduleName.replace("_"," "),"pascal")=== strPortfolio;
  });

  return color?.color;

}

const getPortfolioID=(strPortfolio, portfolioList)=>{
 
  const color= portfolioList?.find((element) => {
    return transformText( element?.moduleName.replace("_"," "),"pascal")=== strPortfolio;
  });

  return color?.id;

}



export {
  transformData,
  bgColors,
  getNodesByModuleName,
  convertToNormalText,
  dynamicRoutesLinks,
  getDynamicRoutesLink,
  getPortfolioColor,
  getPortfolioID
};
