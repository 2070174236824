const recentlyViewedReportsVar = "recently-viewed-reports";

export const RecentlyViewedReport = ({type,report})=> {
    let recentlyViewed = localStorage?.getItem(recentlyViewedReportsVar) || `[]`;
    recentlyViewed = JSON?.parse(recentlyViewed) || [];

    switch (type) {
        case 'set':
            let viewd =  recentlyViewed?.find(f=>f.id===report.id);
            if(viewd){
                recentlyViewed = recentlyViewed?.map(el=>{
                    if(el.id===report.id){
                        el = {
                            ...el,
                            report,
                        }
                    }
                    return el;
                });
            }else{
                recentlyViewed.push(report);
            }
            localStorage.setItem(recentlyViewedReportsVar,JSON.stringify(recentlyViewed));
            break;
        case 'delete':
            recentlyViewed =  recentlyViewed?.filter(f=>f.id!==report.id);
            localStorage.setItem(recentlyViewedReportsVar,JSON.stringify(recentlyViewed));
            break;
        case 'get':
            break;
        default:
            break;
    }
    recentlyViewed.reverse();
    recentlyViewed.slice(0, 20);
    return recentlyViewed;
}