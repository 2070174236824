import { Col, Row, Table } from 'antd';
import React from 'react'

const ActivityTable = ({
    loading,
    tableData,
    tableProps,
    columns,
    pageSizeOptions,
    totalRecords,
    setTablePropsData,
}) => {

    return (
        <React.Fragment>
            <Row gutter={[{sm: 4,md: 4,lg: 8,xl: 12,xxl: 12},4]} justify='end'>
                <Col span={24}>
                    <Table 
                        rowKey={`id`}
                        loading={loading}
                        dataSource={tableData} 
                        columns={columns} 
                        size='small'
                        pagination={{ 
                            showSizeChanger: true, 
                            pageSizeOptions: pageSizeOptions,
                            total: totalRecords,
                            showTotal:(total) => `Total ${total} Items`
                        }}
                        onChange={(pagination, filters, sorter, extra)=>{
                            const { 
                                // currentDataSource, 
                                action 
                            } = extra;
                            if(action==="paginate"){
                                if(tableProps?.length!==pagination?.pageSize){
                                    setTablePropsData({
                                        length:pagination?.pageSize,
                                        start:1,
                                    })
                                }else{
                                    setTablePropsData({
                                        length:pagination?.pageSize,
                                        start:(pagination?.current-1)*pagination?.pageSize+1,
                                    })
                                }
                            }else if(action==="sort"){
                                sorter.column = sorter?.column ? columns?.findIndex(f=>f.key===sorter?.columnKey) : 0;
                                sorter.dir=sorter.order?.replace("end","").toUpperCase() || "DESC";
                                let order = [sorter]
                                setTablePropsData({...tableProps,order});
                            }
                        }}
                        scroll={{
                            x: window?.innerWidth - 450,
                            y: window?.innerHeight - 300,
                        }}
                    />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ActivityTable
