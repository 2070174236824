import React, { useState } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const GraphqlApiList = () => {
    return (
        <div><SwaggerUI url="/openapigraphql.yml" /></div>
    )
}
export default GraphqlApiList;

