import React from 'react';
import { Tooltip } from "antd";

const TextWithTooltip = (props) => {

    const styleTooltip ={
        display:"block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: (props?.characterLimit==="auto"?"-webkit-fill-available":`${props?.characterLimit}ch`),
    }

    const getTooltopText = (text) =>{
        if(props?.text?.length > props?.characterLimit){
            if(props?.tooltip===true){
                return <Tooltip title={props?.tooltipText || props?.text}><span style={styleTooltip} className={props?.className}>{props?.text}</span></Tooltip>;
            }else if(props?.title===false){
                return <span style={styleTooltip} className={props?.className}>{props?.text}</span>;
            }else{
                return <span style={styleTooltip} title={props?.tooltipText || props?.text} className={props?.className}>{props?.text}</span>;
            }
        }else if(props?.isTooltip){
            return <Tooltip title={props?.tooltipText || props?.text}><span style={styleTooltip} className={props?.className}>{props?.text}</span></Tooltip>;
        }else{
            return <span style={styleTooltip} className={props?.className}>{props?.text}</span>;
        }
    }
    return (
        <>
            { getTooltopText(props?.text) }
        </>
    );
}

TextWithTooltip.defaultProps = {
    text:"",
    characterLimit:100,
    tooltip:true,
};
// #endregion

export default TextWithTooltip;