import { Typography } from 'antd';
import React from 'react'

const SummaryChart = ({summaryData,setSummaryData,selectedPortfolio}) => {
    
    const updateChartData = (cData) => {
        let tempData = summaryData?.map(sData=>{
            if(cData?.type===sData.type && cData?.id===sData?.id){
                sData.visible = !cData?.visible;
            }
            sData.portfolio = sData?.portfolio?.map(pData=>{
                if(cData?.type===pData.type && cData?.portfolio_id===pData?.portfolio_id){
                    pData.visible = !cData?.visible;
                }
                return pData
            })
            return sData
        })
        setSummaryData(tempData);
    }

    const getTitle = ({type,data}) => {
        switch (type) {
            case "project": return <Typography.Text
                                    ellipsis={true}
                                    title={data?.name}
                                >
                                    {data?.name}
                                </Typography.Text>
                        //         return <details onClick={(e)=>updateChartData(data)} open={data?.visible || false}>
                        //     <summary className="text-nowrap">
                                
                        //     </summary>
                        // </details>;
            case "task": return <Typography.Text
                                    ellipsis={true}
                                    title={data?.portfolioname}
                                    className='px-2 rounded'
                                    style={{background:data?.color}}
                                >
                                    {data?.portfolioname}
                                </Typography.Text>
                        // <details onClick={()=>updateChartData(data)} open={data?.visible || false}>
                        //     <summary className="text-nowrap">
                        //         <Typography.Text
                        //             ellipsis={true}
                        //             title={data?.portfolioname}
                        //             className='px-2 rounded'
                        //             style={{background:data?.color,maxWidth:250}}
                        //         >
                        //             {data?.portfolioname}
                        //         </Typography.Text>
                        //     </summary>
                        // </details>;
            case "milestone": return <Typography.Text
                                        ellipsis={true}
                                        title={data?.displayname}
                                        className='text-nowrap'
                                    >
                                        {data?.displayname}
                                    </Typography.Text>;
            default: return "";
        }
    }

    return (
        <React.Fragment>
            <table className='table table-bordered table-hover table-sm'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th width={`10%`}>Deadline</th>
                        <th width={`10%`} className='text-right'>Opex</th>
                        <th width={`10%`} className='text-right'>Capex</th>
                        <th width={`10%`} className='text-right'>Total</th>
                        <th width={`10%`} className="text-nowrap text-right"># Impacted Objects</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ((()=>{
                            if(summaryData?.length>0){
                                return summaryData?.map((rowData, index) => {
                                    let total ={
                                        currency: "",
                                        opex:0,
                                        capex:0,
                                        totalexp:0,
                                        total_relations:0,
                                    };
                                    return (
                                        <React.Fragment key={index}>
                                            <tr kay={Math.random()} className="table-active">
                                                <th>
                                                    {getTitle({type:rowData?.type,data:rowData})}
                                                </th>
                                                <td>{}</td>
                                                <td>{}</td>
                                                <td>{}</td>
                                                <td>{}</td>
                                                <td>{}</td>
                                            </tr>
                                            {
                                                ((()=>{
                                                    if(selectedPortfolio?.length>0 && rowData?.portfolio?.length > 0){
                                                        return rowData?.portfolio?.filter(f=>selectedPortfolio?.includes(f.portfolio_id))?.map(portfolio=>{
                                                            return <React.Fragment key={index+Math.random()}>
                                                                <tr kay={Math.random()}>
                                                                    <td>
                                                                        {getTitle({type:portfolio?.type,data:portfolio})}
                                                                    </td>
                                                                    <td>{}</td>
                                                                    <td>{}</td>
                                                                    <td>{}</td>
                                                                    <td>{}</td>
                                                                    <td>{}</td>
                                                                </tr>
                                                                {
                                                                    ((()=>{
                                                                        if(portfolio?.data?.length > 0){
                                                                            return portfolio?.data?.map(data=>{
                                                                                total.currency = data?.currency;
                                                                                total.opex+=Number(data?.opex?.replace(/[^\d\.]*/g, '')) || 0;
                                                                                total.capex+=Number(data?.capex?.replace(/[^\d\.]*/g, '')) || 0;
                                                                                total.totalexp+=Number(data?.totalexp?.replace(/[^\d\.]*/g, '')) || 0;
                                                                                total.total_relations+=Number(data?.total_relations?.replace(/[^\d\.]*/g, '')) || 0;

                                                                                return <React.Fragment key={index+Math.random()}>
                                                                                    <tr kay={Math.random()}>
                                                                                        <td>
                                                                                            {getTitle({type:data?.type,data:data})}
                                                                                        </td>
                                                                                        <td>{data?.end}</td>
                                                                                        <td className='text-right'>{data?.currency} {data?.opex}</td>
                                                                                        <td className='text-right'>{data?.currency} {data?.capex}</td>
                                                                                        <td className='text-right'> {data?.currency} {data?.totalexp}</td>
                                                                                        <td className='text-right'>{data?.total_relations}</td>
                                                                                    </tr>
                                                                                </React.Fragment>
                                                                            })
                                                                        }
                                                                        return <></>
                                                                    })())
                                                                }
                                                            </React.Fragment>
                                                        })
                                                    }
                                                    return <></>
                                                })())
                                            }
                                            {
                                                selectedPortfolio?.length>0 && <React.Fragment>
                                                    <tr kay={Math.random()}>
                                                        <th className='bg-primary text-white'>{}</th>
                                                        <td className='bg-primary text-white'>{rowData?.end || ""}</td>                                                
                                                        <td className='text-right bg-primary text-white'>{total.currency} {total.opex}</td>
                                                        <td className='text-right bg-primary text-white'>{total.currency} {total.capex}</td>
                                                        <td className='text-right bg-primary text-white'>{total.currency} {total.totalexp}</td>
                                                        <td className='text-right bg-primary text-white'>{}{total.total_relations}</td>
                                                    </tr>
                                                </React.Fragment>
                                            }
                                            
                                        </React.Fragment>
                                    );
                                });
                            }else{
                                return <></>
                            }
                        })())
                    }
                </tbody>
            </table>
        </React.Fragment>
    )
}

export default SummaryChart
