import { Flex, message, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { PageTitleHeading } from '../../utils/Common';
import Reports from './personal/Reports';
import PersonalServices from '../../services/services/PersonalServices';
import { RecentlyViewedReport } from './personal/PersonalFn';
import ChnageLogsServices from '../../services/services/ChangeLogsServices';

const ReportRecentlyUsed = () => {
    const [loading,setLoading] = useState(true);
    const [reports,setReports] = useState([]);
    const [users,setUsers] = useState([]);
    const [loginUserEmail] = useState(localStorage.getItem('email'));
    const [open,setOpen] = useState(false);
    const [openShareWith,setOpenShareWith] = useState(false);
    
    useEffect(()=>{
        fetchData();
        fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const fetchUsers = useCallback(async () => {
        try {
            setLoading(true);
            let response = await ChnageLogsServices.getUsers();
            if(response?.data?.data){
                setUsers(response?.data?.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, []) 

    const fetchData = async (filter) => {
        try {
            setLoading(true);
            let response = RecentlyViewedReport({type:'get'});
            if(response){
                setReports(response)
            }else{
                setReports([])
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    const deleteReport = async (id) => {
        try {
            setLoading(true);
            const {data:response} = await PersonalServices?.deleteReport(id);
            if(response?.id){
                if(response?.isDeleted){
                    message.success("Report deleted successfully");
                    fetchData();
                    RecentlyViewedReport({type:'delete',report:response})
                }else{
                    message.success("Failed to delete Report");
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }

    const shareReport = async (report) => {
        try {
            const {data: response} = await PersonalServices.updateReport({...report,id:report?.id});
            if(response?.id){
                message.success("Report Updated Successfully");
                RecentlyViewedReport({type:'set',report:response})
                fetchData()
                setOpenShareWith(false);
            }else{
                message.error("Failed to update report");
            }
        } catch (error) {
            console.log("error: ",error)
        }
    }

    const updateReport = async (report) => {
        try {
            const {data: response} = await PersonalServices.updateReport({...report,id:report?.id});
            if(response?.id){
                message.success("Report Updated Successfully");
                RecentlyViewedReport({type:'set',report:response})
                fetchData()
                setOpen(false);
            }else{
                message.error("Failed to update report");
            }
        } catch (error) {
            console.log("error: ",error)
        }
    }

    return (
        <React.Fragment>
            <Spin spinning={loading} >
                <div className="container-fluid">
                    <Flex vertical gap={8}>
                        <div className="headingbx">
                            <Flex justify='space-between' align='center' gap={4} className='w-100'>
                                <PageTitleHeading text={`Recently Used`} />
                            </Flex>
                        </div>
                        <Reports
                            open={open}
                            setOpen={setOpen}
                            openShareWith={openShareWith}
                            setOpenShareWith={setOpenShareWith}
                            loading={loading}
                            reports={reports}
                            deleteReport={deleteReport}
                            shareReport={shareReport}
                            loginUserEmail={loginUserEmail}
                            updateReport={updateReport}
                            users={users}
                            editable={false}
                        />
                    </Flex>
                </div>
            </Spin>
        </React.Fragment>
    )
}

export default ReportRecentlyUsed
