import React from "react";
import { Tabs } from "antd";
import ApplicationTab from "./ApplicationTab";
import ConnectionTab from "./ConnectionTab";
import LinkTypesCard from "./LinkTypesCard";
import { PageTitleHeading } from "../../utils/Common";
import GlobalTemplate from "./GlobalTemplate";
import CompletenessTab from "./CompletenessTab";
import Delayed from "../../utils/Delayed";
import ShortVideoGuide_New from "./ShortVideoGuide_New";
import WelcomeVideo from "./WelcomeVideo";
import GlobalCurrency from "./GlobalCurrency";

const SettingsContent = ({
  selectedMenuText,
  selectedCollapse,
  selectedMenu,
  items,
  fetchAllPortfolio,
  setUpdatedMenu,
  setActiveTab,
  activeTab,
  menuItems,
  label
}) => {
  const findCheckedValues = (id = null, data = []) => {
    return data?.includes(id) ?? false;
  };
  console.log("activeTab", activeTab);
  const onChange = (key) => {
    setActiveTab(key);
  };
  
  return (
    <Delayed waitBeforeShow={1000}>
      {selectedCollapse === 0 && (
        <>
          <PageTitleHeading text={label} className={`mb-2`} />
          <Tabs
            type="card"
            className="setting-content-tabs"
            defaultActiveKey={"1"}
            activeKey={String(activeTab)}
            onChange={onChange}
            items={[
              {
                label: "Properties",
                key: "1",
                children: (
                  <ApplicationTab
                    selectedMenuText={selectedMenuText}
                    selectedMenu={selectedMenu}
                    item={
                      items?.find((item) => item?.id === selectedMenu) || {}
                    }
                    fetchAllPortfolio={fetchAllPortfolio}
                    setUpdatedMenu={setUpdatedMenu}
                    activeTab={activeTab}
                  />
                ),
              },
              {
                label: "Relations",
                key: "2",
                children: (
                  <ConnectionTab
                    selectedMenuText={selectedMenuText}
                    selectedMenu={selectedMenu}
                    items={
                      items?.map((item) => ({
                        id: item.id,
                        name: item.name,
                        checked: findCheckedValues(
                          item?.id,
                          items?.find((val) => val?.id === selectedMenu)
                            ?.portfolioConnectedIds || []
                        ),
                      })) || {}
                    }
                    fetchAllPortfolio={fetchAllPortfolio}
                  />
                ),
              },
              {
                label: "Completeness",
                key: "3",
                children: (
                  <CompletenessTab
                    selectedMenuText={selectedMenuText}
                    selectedMenu={selectedMenu}
                    items={
                      items?.map((item) => ({
                        id: item.id,
                        name: item.name,
                        checked: findCheckedValues(
                          item?.id,
                          items?.find((val) => val?.id === selectedMenu)
                            ?.portfolioConnectedIds || []
                        )
                      })) || {}
                    }
                    fetchAllPortfolio={fetchAllPortfolio}
                    menuItems={menuItems}
                    checkedPropertyAndRelationItems={{
                      property:
                        items?.find((item) => item?.id === selectedMenu)
                          ?.importantPropertiesIds || [],
                      relation: items?.find((item) => item?.id === selectedMenu)
                        ?.importantRelationsIds,
                    }}
                    selectedPortfolioItem={
                      items?.find((item) => item?.id === selectedMenu) || {}
                    }
                  />
                ),
              },
            ]}
          />
        </>
      )}
      {(selectedCollapse === 1 && selectedMenuText === "Global Properties" && (
        <GlobalTemplate
          selectedMenuText={selectedMenuText}
          selectedMenu={selectedMenu}
          item={items?.find((item) => item?.id === selectedMenu) || {}}
          fetchAllPortfolio={fetchAllPortfolio}
          setUpdatedMenu={setUpdatedMenu}
          activeTab={activeTab}
        />
      )) ||
      (selectedCollapse === 1 && selectedMenuText === "Global Currency" && (
        <GlobalCurrency
          selectedMenuText={selectedMenuText}
          selectedMenu={selectedMenu}
          item={items?.find((item) => item?.id === selectedMenu) || {}}
          fetchAllPortfolio={fetchAllPortfolio}
          setUpdatedMenu={setUpdatedMenu}
          activeTab={activeTab}
        />
      )) ||
        (selectedCollapse === 1 && selectedMenuText === "Link Type" && (
          <LinkTypesCard />
        ))}
      {selectedCollapse === 1 && selectedMenuText === "User Guide Library" && (
        <>
          {/* <PageTitleHeading text={label} className={`mb-2`} /> */}
          {/* <ShortVideoGuide/> */}
          <ShortVideoGuide_New/>
        </>
      )}
      {selectedCollapse === 1 && selectedMenuText === "Welcome Video Library" && (
        <>
          {/* <PageTitleHeading text={label} className={`mb-2`} /> */}
          {/* <ShortVideoGuide/> */}
          <WelcomeVideo/>
        </>
      )}
    </Delayed>
  );
};

export default SettingsContent;
