import { Button, Col, DatePicker, Input, Row, Select } from 'antd'
import moment from 'moment'
import React from 'react'

const TableFilter = ({tableProps={},setTablePropsData=()=>{},users=[]}) => {
    return (
        <React.Fragment>
            <Row gutter={[{sm: 4,md: 4,lg: 8,xl: 12,xxl: 12},4]} justify='end'>
                {/* <Col xxl={10} xl={9} lg={3} md={8} sm={24}></Col> */}
                {/* <Col xxl={3} xl={3} lg={4} md={8} sm={12}>
                    <Select
                        placeholder={`Search Portfolio Name`}
                        className='w-100'
                        defaultValue={[]}
                        value={tableProps?.search?.ModuleName || []}
                        onChange={(v)=>{
                            setTablePropsData({
                                search:{
                                    ...tableProps?.search,
                                    ModuleName:v,
                                },
                            })
                        }}
                        options={
                            getAllMenuPortfolio()?.map(item=>{
                                return {
                                    label: item?.name,
                                    value : item?.moduleName
                                }
                            })
                        }
                    />
                </Col> */}
                {/* <Col xxl={3} xl={3} lg={4} md={8} sm={12}>
                    <Select
                        placeholder={`Search Action Type`}
                        className='w-100'
                        defaultValue={[]}
                        value={tableProps?.search?.TransactionType || []}
                        onChange={(v)=>{
                            setTablePropsData({
                                search:{
                                    ...tableProps?.search,
                                    TransactionType:v,
                                },
                            })
                        }}
                        options={
                            ["ADD","UPDATE","DELETE"]?.map(v=>({ label:v,value:v }))
                        }
                    />
                </Col> */}
                <Col xxl={3} xl={3} lg={4} md={8} sm={12}>
                    <Select
                        mode="multiple"
                        placeholder={`Search Users`}
                        className='w-100'
                        defaultValue={[]}
                        value={tableProps?.search?.useremails || []}
                        onChange={(v)=>{
                            setTablePropsData({
                                search:{
                                    ...tableProps?.search,
                                    useremails:v,
                                },
                            })
                        }}
                        options={
                            users?.map(item=>{
                                return {
                                    label: `${item?.name}(${item?.email})`,
                                    value : item?.email
                                }
                            })
                        }
                    />
                </Col>
                <Col xxl={4} xl={4} lg={7} md={12} sm={24}>
                    <DatePicker.RangePicker
                        className='w-100'
                        format="DD-MM-YYYY"
                        value={[
                            (tableProps?.search?.from_date) ? moment(tableProps?.search?.from_date,'DD-MM-YYYY') : false,
                            (tableProps?.search?.to_date) ? moment(tableProps?.search?.to_date,'DD-MM-YYYY') : false,
                        ]}
                        onChange={(dates,dateStrings)=>{
                            console.log(dates,dateStrings);
                            
                            setTablePropsData({
                                search:{
                                    ...tableProps?.search,
                                    from_date: dateStrings[0] || "",
                                    to_date: dateStrings[1] || "",
                                },
                            })
                        }}
                    />
                </Col>
                <Col xxl={3} xl={3} lg={4} md={9} sm={16}>
                    <Input
                        className='w-100'
                        placeholder='Search Here...'
                        value={tableProps?.search?.value || ""}
                        onChange={(e)=>{
                            setTablePropsData({
                                search:{
                                    ...tableProps?.search,
                                    value:e.target.value,
                                },
                                start:1,
                            })
                        }}
                    />
                </Col>
                <Col xxl={1} xl={2} lg={2} md={3} sm={8}>
                    <Button 
                        htmlType='button'
                        className='w-100 px-2'
                        onClick={()=>{
                            setTablePropsData({
                                search:{
                                    value:"",
                                },
                            })
                        }}
                    >Clear</Button>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default TableFilter
