import { Bar } from "@ant-design/plots";
import React, { useEffect, useState } from "react";
// import { GanttOriginal, Task, ViewMode } from "react-gantt-chart";
import { Gantt, Task, ViewMode } from "gantt-task-react-awa";
import "gantt-task-react-awa/dist/index.css";


function CompareChart({ events }) { 
 
  
//   const data =	[
//   	{
//   		type: "project",
//   		id: "ScenarioID",
//   		name: "Scenario 1 ",
//   		start: new Date('2021-06-01'),
//   		end: new Date(2021, 9, 30),
//   		progress: 100,
//   		hideChildren: false,
//   	},
//   	{
//   		type: "task",
//   		id: "AppliationID ",
//   		name: "Application ",
//   		start: new Date(2021, 6, 1),
//   		end: new Date(2021, 6, 30),
//   		progress: 100,
//   		project: "ScenarioID",
//   	},

//   	{
//   		type: "task",
//   		id: "BusinessProcessID",
//   		name: "Business  Process",
//   		start: new Date(2021, 7, 1),
//   		end: new Date(2021, 7, 30),
//   		progress: 25,
//   		//dependencies: ["Task 0"],
//   		project: "ScenarioID",
//   	},

//       {
//   		type: "milestone",
//   		id: "m1",
//   		name: "Process  2",
//   		start: new Date(2021, 7, 1),
//   		end: new Date(2021, 7, 30),
//   		progress: 100,
//   		//dependencies: ["Task 2"],
//   		project: "ScenarioID",
//   	},

//   	{
//   		type: "task",
//   		id: "BusinessCapabilityID",
//   		name: "Business Capability",
//   		start: new Date(2021, 6, 1),
//   		end: new Date(2021, 7, 30),
//   		progress: 10,
//   		//dependencies: ["Task 1"],
//   		project: "ScenarioID",
//   	},
//   	{
//   		type: "milestone",
//   		id: "Task 6",
//   		name: "1.3.1 MileStone (KT)",
//   		start: new Date(2021, 6, 1),
//   		end: new Date(2021, 6, 30),
//   		progress: 100,
//   		dependencies: ["Task 2"],
//   		project: "ScenarioID",
//   	},
//       {
//   		type: "project",
//   		id: "Scenario2",
//   		name: "Scenario 2  ",
//   		start: new Date('2021-06-01'),
//   		end: new Date(2021, 9, 30),
//   		progress: 100,
//   		hideChildren: false,
//   	},
//   	{
//   		type: "task",
//   		id: "ApplicationID2",
//   		name: "Application",
//   		start: new Date(2021, 6, 1),
//   		end: new Date(2021, 6, 30),
//   		progress: 45,
//   		project: "Scenario2",
//   	},
//   	{
//   		type: "task",
//   		id: "BusinessCapabilityID2",
//   		name: "Business Capability",
//   		start: new Date(2021, 7, 1),
//   		end: new Date(2021, 7, 30),
//   		progress: 25,
//   		//dependencies: ["Task 01"],
//   		project: "Scenario2",
//   	},
//   	{
//   		type: "task",
//   		id: "BusinessProcessID2",
//   		name: "Business Process",
//   		start: new Date(2021, 6, 1),
//   		end: new Date(2021, 7, 30),
//   		progress: 10,
//   		//dependencies: ["Task 12"],
//   		project: "Scenario2",
//   	}
//   ];

  const [taskList, setTaskList] = useState([]);

  //   {
  // 	type: "project",
  // 	id: "0",
  // 	name: "",
  // 	start: new Date('2021-06-01'),
  // 	end: new Date(2021, 9, 30),
  // 	progress: 100,
  // 	hideChildren: false,
  // }

  useEffect(() => {
    if (events) {
      setTaskList(events);
    }
  }, [events]);

  //   const events = [
  //     { name: "event planning", startTime: "2024-04-01", endTime: "2024-12-04" },
  //     {
  //       name: "layout logistics",
  //       startTime: "2024-09-03",
  //       endTime: "2024-11-13",
  //     },
  //     { name: "select vendors", startTime: "2024-10-05", endTime: "2025-03-01" },
  //     { name: "hire venue", startTime: "2024-09-09", endTime: "2024-12-01" },
  //     { name: "hire caterer", startTime: "2024-10-01", endTime: "2025-09-14" },
  //     {
  //       name: "hire event decorators",
  //       startTime: "2024-11-12",
  //       endTime: "2025-10-17",
  //     },
  //     { name: "rehearsal", startTime: "2024-09-14", endTime: "2026-09-16" },
  //     {
  //       name: "event celebration",
  //       startTime: "2024-12-17",
  //       endTime: "2025-01-01",
  //     },
  //   ];
 
  const handleDblClick = (task) => {
    // console.log("On Double Click event Id:" + task.id);
  };

  const handleExpanderClick = (task) => {
    // console.log("On expander click Id:", task, task.id);
    setTaskList(() => taskList.map((t) => (t.id === task.id ? task : t)));
  };

  return (
    <div>
     
	 { taskList && taskList.length && 
	  // <GanttOriginal
    //     tasks={taskList}
    //     viewMode={ViewMode.Month}
    //     columnWidth={70}
		//     rowHeight={50}
    //     ganttHeight={500}
    //     listCellWidth={true ? "100px" : ""}
    //     onDoubleClick={handleDblClick}
    //     onExpanderClick={handleExpanderClick}
    //     //projectBackgroundColor={'#fff187'}
    //     //arrowColor={'#000fff'}
		// //TooltipContent= {MyToolTipComponent}
		// locale= {"en-IN"}
		// rtl= {false} 
    //   />
      
      <Gantt
          tasks={taskList}
          viewMode={ViewMode.Month}
          columnWidth={70}
          rowHeight={30}
          //ganttHeight={500}
          listCellWidth={true ? "500px" : ""}
          onDoubleClick={handleDblClick}
          onExpanderClick={handleExpanderClick}
        // onDateChange={handleTaskChange}
        // onDelete={handleTaskDelete}
        // onProgressChange={handleProgressChange}
        // onClick={handleClick}
        // onSelect={handleSelect}

        projectBackgroundColor={'#59a985'}
        //arrowColor={'#000fff'}
        //TooltipContent= {MyToolTipComponent}
        //locale={"en-IN"}
        // rtl={false}
        />
      }
    </div>
  );
}

export default CompareChart;
