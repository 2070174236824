import http from "./httpService";
import { encodeQueryData } from "../../utils/Common";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getCurrencyList() {
    const url = `${API_BASE_URL}currency`;
    let response = await http.get(url);
    return response;
}

async function createCurrency(data) {
    const url = `${API_BASE_URL}currency`;
    let response = await http.post(url, data);
    return response;
}

  
async function updateCurrency( id,data) { 
    const url = `${API_BASE_URL}currency/${id}`;
    let response = await http.put(url, data);
    return response;
  }
   
async function getCurrencyListById(id) {
    const url = `${API_BASE_URL}currency/`;
    let response = await http.get(url + id);
    return response;
  }

  
async function deleteCurrency(id) {
  const url = `${API_BASE_URL}currency/`;
  let response = await http.delete(url + id);
  return response;
}

  export default {
    getCurrencyList,
    createCurrency,
    updateCurrency,
    getCurrencyListById ,
    deleteCurrency
  };