import React from 'react'
import diagramServices from '../../services/services/diagramServices';
import { pascalToSnake } from '../../utils/Common';
import ScenarioServices from '../../services/services/ScenarioServices';
import { message } from 'antd';

const BpmnEditorFn = props => {
    return (
        <div>
        
        </div>
    )
}

export default BpmnEditorFn;

export const getDiagram = async ({setLoading,diagramId,setDiagramData}) => {
    try {
        setLoading(true);
        const response = await diagramServices.getDiagram(diagramId);
        setDiagramData(response?.data || {})
        setTimeout(() => {
            setLoading(false); 
        }, 2000);
    } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
    }
};

export const handleFileSelect = ({event,setLoading,openBpmnDiagram}) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const xml = e.target.result;
            openBpmnDiagram(xml);
            setLoading(false);
        };
        reader.readAsText(file);
    }
};

export const getTitleData = ({diagramData,moduleData}) =>{
    let mName= "";
    if(diagramData?.moduleName==="BusinessApplication"){
        mName = "Application";
    }else{
        mName = diagramData?.moduleName?.replace(/([a-z])([A-Z])/g, '$1 $2');
    }


    return <div>
        <div className='mt-3 mb-2 h2'>{mName}: {moduleData?.titleName}</div>
        <div className='h4'>Diagram: {diagramData?.name}</div>
    </div>
}

export const exportContent = ({type,modelerRef,name}) =>{
    if(type==="bpmn"){
        modelerRef.current.saveXML({ format: true }, (err, xml) => {
            if (err) {
                console.error('Error exporting BPMN diagram:', err);
            } else {
                const blob = new Blob([xml], { type: 'application/xml' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `${name}.${type}`;
                link.click();
            }
        });
    }else {
        modelerRef.current.saveSVG({ format: true }, (err, svg) => {
            if (err) {
                console.error('Error exporting BPMN diagram:', err);
            } else {
                const blob = new Blob([svg], { type: 'application/svg' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `${name}.${type}`;
                link.click();
            }
        });
    }
}

export const saveDiagram = ({modelerRef, setLoading, diagramId, diagramData}) => {
    modelerRef.current.saveSVG({ format: true },async (err, svg) => {
        if(err) {
            console.error('Error saving BPMN diagram:', err);
            setLoading(false);
        }else {
            svg = `data:image/svg+xml;base64,${btoa(svg)}`;
            modelerRef.current.saveXML({ format: true },async (err, xml) => {
                if (err) {
                  console.error('Error saving BPMN diagram:', err);
                  setLoading(false);
                } else {
                    try {
                        setLoading(true);
                        let obj = {
                            "id": diagramId,
                            "name": diagramData?.name || "",
                            "description": diagramData?.description || "",
                            "moduleName": diagramData?.moduleName || "",
                            "moduleID":diagramData?.moduleID || "",
                            "xml": xml,
                            "svg": svg,
                            "item_names": '',
                        };
                        if (obj && typeof obj === 'object') {
                            obj.UserId = localStorage?.email;
                        }
                        const response = await diagramServices.updatediagram(obj);
                        const newData = response?.data || null;
                
                        let modifiedModuleName = obj?.moduleName;
                        if (modifiedModuleName === 'BusinessProcess') {
                                modifiedModuleName = 'BusinessProcesses';
                            }
                            else if (modifiedModuleName === 'ApqcsProcess') {
                                modifiedModuleName = 'ApqcsProcesses';
                            }
                        const objdata = {
                            source_id: obj?.moduleID,
                            modulename: pascalToSnake(modifiedModuleName),
                            tab_name: "Diagram",
                        };
                        await ScenarioServices.checkscenariomodifyrecord(objdata);
                
                        // Handle success response
                        message.success("Updated successfully");
                        setTimeout(() => {
                            setLoading(false);
                        }, 1000);
                    } catch (error) {
                        console.log("catcheEror",error)
                        setLoading(false);
                    }
                }
            });             
        }
    })
    
}