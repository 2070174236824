import React, { useEffect, useState } from 'react'
import { PageTitleHeading } from '../../../utils/Common'
import { useNavigate } from 'react-router-dom';
import { Col, Typography } from 'antd';

const RecentlyViewedV2 = () => {
    const navigate = useNavigate();
    const [list, setList] = useState([]);

    useEffect(() => {
        getRecentViewed_Portfolio();
    },[]);
 
    const getRecentViewed_Portfolio=()=>{ 
        let recentPortfolio = localStorage.getItem("recent_viewed_portfolio"); 
        let recentPortfolio_json= JSON.parse(recentPortfolio)?.reverse().slice(0, 9);

        setList(recentPortfolio_json);  
    }
    
    return (
        <React.Fragment>
            <Col flex={`auto`}>
                <PageTitleHeading text={"Recently Viewed"} className={`my-0`}/>
                <table className='table table-sm'>
                    <thead className="table-active">
                        <tr>
                            <th> Name </th>
                            <th> Portfolio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {   
                            list?.map((item) => {
                                return <React.Fragment key={Math?.random()}>
                                    <tr>
                                        <td className='pt-2'>
                                            <Typography.Text 
                                                ellipsis={true}
                                                title={item?.ModelName}
                                                style={{maxWidth:100}}
                                                onClick={()=>{ navigate(item.URL)}}
                                                className='pointer'
                                            >
                                                {item?.ModelName}
                                            </Typography.Text>
                                        </td>
                                        <td>
                                            <div style={{background : item?.color}} className='p-1 cu-border-rounded text-nowrap'>{item?.Module}</div>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            })
                        }
                    </tbody>
                </table>
            </Col>
        </React.Fragment>
    )
}

export default RecentlyViewedV2
